const apigClientFactory = require('aws-api-gateway-client').default;
import { store } from '../app/store';

let apiId = '';
export const setApiGatwayId = (config: any) => {
    apiId = config.api_gateway_config.api_id;
};

export const getApiGatwayUrl = () => `https://${apiId}.execute-api.us-east-1.amazonaws.com/prod`;

export default (
    deviceId: string,
    vin: string,
    forceStrictValidation: boolean,
    setResult: (success: boolean, r: any) => void,
) => {
    // extract endpoint from config
    const client = apigClientFactory.newClient({
        invokeUrl: getApiGatwayUrl(),
        retries: 3,
    });

    client
        .invokeApi(
            {},
            '/device_status_info',
            'GET',
            {
                queryParams: {
                    deviceId: deviceId,
                    vin: vin,
                    strictValidation: forceStrictValidation,
                },
                headers: {
                    Authorization: store.getState().userData.userInfo!.jwt,
                },
            },
            null,
        )
        .then((response: any) => {
            setResult(true, JSON.parse(response.data));
        })
        .catch((response: any) => {
            setResult(false, response);
        });
};
