import * as React from 'react';
import { Button, SpaceBetween, Header, FormField, Input, Form } from '@amzn/awsui-components-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CameraModal from './cameraModal';
import { EventName, EventType, logMetricEvent } from '../services/eventService';
import decodeImage from './camera/imageDecoder';

export enum CheckDeviceMode {
    VIN_CHECK_ONLY = 'Insert Vehicle information you see',
    DEVICE_AND_VIN_CHECK = 'Insert Vehicle/Device information you see',
}

type onSubmitInput = {
    deviceSerialNumber: string;
    vin: string;
    cameraDeviceSerialNumber: string;
    cameraVin: string;
};

type Props = {
    checkDeviceMode: CheckDeviceMode;
    submitButtonString: string;
    onSubmit: (input: onSubmitInput) => void;
    onClear: () => void;
    onStop: () => void;
    submitButtonLoading: boolean;
    initialVin: string;
    initialDeviceSerialNumber: string;
    validInputs?: {
        isValidVin: boolean;
        isValidDeviceId: boolean;
    };
};
const CheckDeviceInfo = ({
    checkDeviceMode,
    submitButtonString,
    onSubmit,
    submitButtonLoading,
    initialVin,
    initialDeviceSerialNumber,
    onClear,
    onStop,
    validInputs,
}: Props) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const [currentConfig, setCurrentConfig] = useState('serial');
    const [vin, setVin] = useState(initialVin);
    const [deviceSerialNumber, setDeviceSerialNumber] = useState(initialDeviceSerialNumber);
    const [cameraVin, setVinFromCamera] = useState(initialVin);
    const [cameraDeviceSerialNumber, setDeviceSerialNumberFromCamera] = useState(initialDeviceSerialNumber);
    const [errorText, setErrorText] = useState('');
    const [vinCameraLoading, setVinCameraLoading] = useState(false);
    const [serialCameraLoading, setSerialCameraLoading] = useState(false);
    const [isVinValid, setIsVinValid] = useState(true);
    const [isDeviceSerialNumberValid, setIsDeviceSerialNumberValid] = useState(true);

    const setVinFromImage = (img: any) => {
        setVinCameraLoading(true);
        const decodeStartTime = Date.now();

        decodeImage(img, (text?: string, error?: any) => {
            let decodeSuccess = false;
            if (text) {
                decodeSuccess = validateVin(vin);
                setVin(vin);
                setVinFromCamera(vin);
            }
            setVinCameraLoading(false);
            logMetricEvent({
                eventName: EventName.VIN_DECODE_FROM_CAMERA,
                eventType: decodeSuccess ? EventType.SUCCESS : EventType.FAILURE,
                duration_ms: Date.now() - decodeStartTime,
            });
        });
    };

    const setSerialFromQRCode = (img: string) => {
        setSerialCameraLoading(true);
        const decodeStartTime = Date.now();

        decodeImage(img, (text?: string, error?: any) => {
            let decodeSuccess = false;
            if (text) {
                decodeSuccess = validateDeviceSerialNumber(text);
                setDeviceSerialNumber(text);
                setDeviceSerialNumberFromCamera(text);
            }
            setSerialCameraLoading(false);
            logMetricEvent({
                eventName: EventName.SERIAL_NUMBER_DECODE_FROM_CAMERA,
                eventType: decodeSuccess ? EventType.SUCCESS : EventType.FAILURE,
                duration_ms: Date.now() - decodeStartTime,
            });
        });
    };

    const validateVin = useCallback((vin: string) => {
        return vin.length == 17 && vin.toUpperCase() === vin;
    }, []);

    const validateDeviceSerialNumber = useCallback((deviceSerialNumber: string) => {
        return (
            deviceSerialNumber.length == 16 &&
            deviceSerialNumber.toUpperCase() === deviceSerialNumber &&
            deviceSerialNumber.startsWith('MS')
        );
    }, []);

    const config: { [key: string]: any } = useMemo(() => {
        return {
            vin: {
                config_name: 'vin',
                callback: setVinFromImage,
                validateText: validateVin,
                setText: setVin,
                formHeader: 'Vehicle VIN',
                cameraHeader: 'Take Picture of VIN Barcode or text',
                state: vin,
                setState: setVin,
            },
            serial: {
                config_name: 'serial',
                callback: setSerialFromQRCode,
                validateText: validateDeviceSerialNumber,
                setText: setDeviceSerialNumber,
                formHeader: 'Device Serial Number',
                cameraHeader: 'Take Picture of Device Serial Number QR Code',
                state: deviceSerialNumber,
                setState: setDeviceSerialNumber,
            },
        };
    }, [vin, deviceSerialNumber]);

    useEffect(() => {
        if (validInputs) {
            const validVin = validInputs['isValidVin'];
            const validDeviceSerialNumber = validInputs['isValidDeviceId'];
            if (validVin && validDeviceSerialNumber) return;
            if (!validVin) {
                setIsVinValid(false);
                setErrorText('Vehicle VIN is not valid in the system');
            } else if (!validDeviceSerialNumber) {
                setIsDeviceSerialNumberValid(false);
                setErrorText('Device Serial Number is not valid in the system');
            }
            if (!validVin || !validDeviceSerialNumber) {
                logMetricEvent({
                    deviceId: config['serial']['state'],
                    vin: config['vin']['state'],
                    eventName: EventName.DEVICE_INSTALLATION,
                    eventType: EventType.FAILED_VALIDATION,
                    miscEventData: {
                        validInputs: validInputs,
                    },
                });
            }
            onStop();
        }
    }, [validInputs]);

    const onSubmitForm = (formData: any) => {
        formData.preventDefault();
        let validVin = true;
        let validDeviceSerialNumber = true;
        const checkDeviceSerialNumber = checkDeviceMode === CheckDeviceMode.DEVICE_AND_VIN_CHECK;
        if (!vin || vin.length <= 0) {
            setErrorText('Vehicle VIN is required');
            validVin = false;
        } else if (!validateVin(vin)) {
            setErrorText('Vehicle VIN should be 17 characters in length & uppercase');
            validVin = false;
        } else if (checkDeviceSerialNumber && (!deviceSerialNumber || deviceSerialNumber.length <= 0)) {
            setErrorText('Device Serial Number is required');
            validDeviceSerialNumber = false;
        } else if (checkDeviceSerialNumber && !validateDeviceSerialNumber(deviceSerialNumber)) {
            setErrorText('Device Serial Number should start with MS & be 16 characters in length & uppercase');
            validDeviceSerialNumber = false;
        } else {
            setErrorText('');
            onSubmit({
                deviceSerialNumber,
                vin,
                cameraDeviceSerialNumber,
                cameraVin,
            });
        }
        setIsVinValid(validVin);
        setIsDeviceSerialNumberValid(validDeviceSerialNumber);
    };

    const onClearData = (formData: any) => {
        formData.preventDefault();
        setErrorText('');
        setDeviceSerialNumber('');
        setDeviceSerialNumberFromCamera('');
        setVin('');
        setVinFromCamera('');
        onClear();
    };

    const cameraVinButtonClick = () => {
        setCurrentConfig(config['vin'].config_name);
        setVisibleModal(true);
    };

    const cameraDeviceButtonClick = () => {
        setCurrentConfig(config['serial'].config_name);
        setVisibleModal(true);
    };

    return (
        <div>
            <CameraModal
                setUserImageCallback={config[currentConfig].callback}
                isValidTextOutput={config[currentConfig].validateText}
                setTextOutput={config[currentConfig].setText}
                visible={visibleModal}
                setVisible={setVisibleModal}
                header={config[currentConfig].cameraHeader}
            />
            <form onSubmit={(e) => e.preventDefault()}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            {submitButtonLoading ? (
                                <Button onClick={onStop}>Stop</Button>
                            ) : (
                                <Button onClick={onClearData}>Clear</Button>
                            )}
                            <Button variant="primary" loading={submitButtonLoading} onClick={onSubmitForm}>
                                {submitButtonString}
                            </Button>
                        </SpaceBetween>
                    }
                    errorText={errorText}
                >
                    <Header variant="h2">{checkDeviceMode}</Header>
                    <SpaceBetween direction="vertical" size="xs">
                        <FormField label={config['vin'].formHeader}>
                            <Input
                                value={config['vin'].state}
                                onChange={({ detail }) => config['vin'].setState(detail.value.toUpperCase())}
                                invalid={!isVinValid}
                            />
                            <Button
                                variant="inline-icon"
                                iconName={'video-on'}
                                loading={vinCameraLoading}
                                onClick={cameraVinButtonClick}
                            />
                        </FormField>
                        {checkDeviceMode === CheckDeviceMode.DEVICE_AND_VIN_CHECK && (
                            <FormField label={config['serial'].formHeader}>
                                <Input
                                    value={config['serial'].state}
                                    onChange={({ detail }) => config['serial'].setState(detail.value.toUpperCase())}
                                    invalid={!isDeviceSerialNumberValid}
                                />
                                <Button
                                    variant="inline-icon"
                                    iconName={'video-on'}
                                    loading={serialCameraLoading}
                                    onClick={cameraDeviceButtonClick}
                                />
                            </FormField>
                        )}
                    </SpaceBetween>
                </Form>
            </form>
        </div>
    );
};

export default CheckDeviceInfo;
