import { createSlice } from '@reduxjs/toolkit';
type UserInfo = {
    userInfo:
        | undefined
        | {
              'custom:company'?: string;
              email?: string;
              expire?: any;
              family_name?: string;
              group?: any;
              sub?: any;
              username?: any;
              jwt?: any;
          };
};

const initialState: UserInfo = {
    userInfo: undefined,
};
export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        updateUserInfo: (state, param) => {
            state.userInfo = param.payload;
        },
        clearUserInfo(state) {
            state.userInfo = undefined;
        },
    },
});

export const userInfoReducer = userInfoSlice.reducer;
export const { updateUserInfo, clearUserInfo } = userInfoSlice.actions;
