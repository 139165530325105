import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Container, ProgressBar, SpaceBetween } from '@amzn/awsui-components-react';
import DeviceInformation from '../../components/deviceInformation';
import deviceStatusHandler from '../../services/deviceStatusHandler';
import CheckDeviceInfo, { CheckDeviceMode } from '../../components/CheckDeviceInfo';
import FailureModal from '../../components/failureModal';
import { useSearchParams, useParams, useLocation } from 'react-router-dom';
import { EventName, EventType, logMetricEvent } from '../../services/eventService';
import { useDispatch, useSelector } from 'react-redux';
import { updateDeviceSerial, updateVin } from '../../slice/onboardingPageSlice';
import { addInstall } from '../../slice/installationDataSlice';
import { AppConfigContext } from '../../config/appConfigContext';
import { POSSIBLE_ERROR_CODES } from '../../components/reportFailure';
import DefectInformation from '../../components/defectInformation';

enum OnboardingFormState {
    START = 100,
    START_CANCELLED,
    START_DATA_RESET,
    INPUT_VALIDATION_FAILURE = 200,
    IN_PROGRESS = 300,
    FINISHED_SUCCESS = 400,
    FINISHED_FAILURE,
    REPORTING_FAILURE = 500,
    FAILURE_REPORTED,
}

const FIVE_MINUTES = 5 * 60 * 1000; // milliseconds

const OnboardingForm = () => {
    const [formState, setFormState] = useState<OnboardingFormState>(OnboardingFormState.START);
    const [deviceInformation, setDeviceInformation] = useState(undefined);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const inputVin = params.get('vin') || '';
    const [inputDeviceSerialNumber, setInputDeviceSerialNumber] = useState('');

    const [progressPercentage, setProgressPercentage] = useState(0);
    const [showFailureModal, setShowFailureModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [defectInformation, setDefectInformation] = useState(null);
    const [startTime, setStartTime] = useState(0);
    const [vin, setVin] = useState(inputVin || '');

    const formStoredDeviceId = useSelector((state: any) => state.onboardingPage.deviceSerial || '');

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { config }: { config: any } = useContext(AppConfigContext);

    const waitTime = useMemo(() => {
        let overrideWait = searchParams.get('override_wait');
        const defaultWaitTime = config.installation_config.install_wait_time_minutes;
        return overrideWait ? +overrideWait : defaultWaitTime;
    }, [config, searchParams]);

    const markDone = useCallback(
        (success: boolean, data?: any) => {
            setFormState(success ? OnboardingFormState.FINISHED_SUCCESS : OnboardingFormState.FINISHED_FAILURE);
            dispatch(
                addInstall({
                    vin: inputVin,
                    deviceSerial: inputDeviceSerialNumber,
                    vinCheck: data && inputVin === data['vin'],
                    installationSuccessful: success,
                    time: Date.now() / 1000,
                }),
            );

            logMetricEvent({
                deviceId: inputDeviceSerialNumber,
                vin: inputVin,
                eventName: EventName.DEVICE_INSTALLATION,
                eventType: success ? EventType.SUCCESS : EventType.FAILURE,
                miscEventData: {
                    deviceInformation: data,
                    progressPercentage: progressPercentage,
                },
                duration_ms: Date.now() - startTime,
            });
            setProgressPercentage(0);
        },
        [progressPercentage, startTime, inputVin, inputDeviceSerialNumber],
    );

    const callback = useCallback(
        (success: boolean, data: any) => {
            if (formState !== OnboardingFormState.IN_PROGRESS) {
                return;
            }
            if (success) {
                if (data['defectInformation']) {
                    // check if we already set this, we dont need to set again
                    if (!defectInformation) {
                        setDefectInformation(data['defectInformation']);
                    }
                }
                setDeviceInformation(data);
                if (data['vin'] && inputVin !== data['vin']) {
                    // if VINs don't match, fast track to failure
                    markDone(false, data);
                } else if (data['platformDeploymentTriggeredTime']) {
                    if (inputVin === data['vin']) {
                        // SUCCESS
                        setProgressPercentage(100);
                        markDone(true, data);
                    } else {
                        // if deployment triggered but vin check failed, then we are in big problems
                        markDone(false, data);
                    }
                } else if (data['installerDeploymentCompletedTime']) {
                    if (inputVin !== data['vin']) {
                        // if we hit here it's a failure
                        markDone(false, data);
                    } else {
                        setProgressPercentage(90);
                    }
                } else if (data['installerDeploymentTriggeredTime']) {
                    if (inputVin !== data['vin']) {
                        // if we hit here it's a failure
                        markDone(false, data);
                    } else if (data['latestStatusCodes'] && data['latestStatusCodesTime']) {
                        // Cancel installation if we get a status code before deployment finishes
                        const latestStatusCode = data['latestStatusCodes'][0];
                        const statusCodeErrorMessage = {
                            ...POSSIBLE_ERROR_CODES[latestStatusCode],
                            error_code: latestStatusCode,
                        };

                        markDone(false, data);
                        onReportFailureCallBack(statusCodeErrorMessage);
                    } else {
                        setProgressPercentage(80);
                    }
                } else if (data['activationCompletedTime']) {
                    setProgressPercentage(70);
                }
            }

            if (startTime + 60 * 1000 * waitTime < Date.now()) {
                markDone(false);
            }
        },
        [formState, startTime, markDone],
    );

    const onClear = useCallback(() => {
        setFormState(OnboardingFormState.START_DATA_RESET);
        dispatch(updateVin(''));
        dispatch(updateDeviceSerial(''));
        setDefectInformation(null);
        setDeviceInformation(undefined);
        setProgressPercentage(0);
        setErrorMessage(null);
    }, []);

    useEffect(() => {
        if (formState !== OnboardingFormState.IN_PROGRESS) {
            return;
        }
        deviceStatusHandler(inputDeviceSerialNumber, inputVin, true, callback);
        const timer = setInterval(() => {
            deviceStatusHandler(inputDeviceSerialNumber, inputVin, false, callback);
        }, 1000 * 30);
        return () => clearInterval(timer);
    }, [formState, callback, inputDeviceSerialNumber, inputVin]);

    const onSubmitForm = useCallback(({ deviceSerialNumber, vin, cameraDeviceSerialNumber, cameraVin }: any) => {
        setFormState(OnboardingFormState.IN_PROGRESS);
        const time = Date.now();
        setStartTime(time);
        setProgressPercentage(10);
        setVin(inputVin);
        if (vin !== cameraVin) {
            //assuming a manual change was made
            logMetricEvent({
                deviceId: deviceSerialNumber,
                vin: vin,
                eventName: EventName.MANUAL_VIN_MODIFICATION,
                eventType: EventType.COUNT,
                miscEventData: {
                    vinFromCamera: cameraVin,
                },
            });
        }
        setInputDeviceSerialNumber(deviceSerialNumber);
        if (deviceSerialNumber !== cameraDeviceSerialNumber) {
            //assuming a manual change was made
            logMetricEvent({
                deviceId: deviceSerialNumber,
                vin: vin,
                eventName: EventName.MANUAL_DEVICE_SERIAL_NUMBER_MODIFICATION,
                eventType: EventType.COUNT,
                miscEventData: {
                    deviceSerialNumberFromCamera: cameraDeviceSerialNumber,
                },
            });
        }
        //set form fields so state will stay
        dispatch(updateVin(vin));
        dispatch(updateDeviceSerial(deviceSerialNumber));
        logMetricEvent({
            deviceId: deviceSerialNumber,
            vin: vin,
            eventName: EventName.DEVICE_INSTALLATION,
            eventType: EventType.START,
            miscEventData: {
                startTime: time,
            },
            duration_ms: 0,
        });
    }, []);

    const onReportFailure = useCallback((e: any) => {
        e.preventDefault();
        setFormState(OnboardingFormState.REPORTING_FAILURE);
        setShowFailureModal(true);
    }, []);

    const onReportFailureCallBack = useCallback(
        (errorMessage: any) => {
            setFormState(OnboardingFormState.FAILURE_REPORTED);
            setErrorMessage(errorMessage);

            logMetricEvent({
                deviceId: inputDeviceSerialNumber,
                vin: inputVin,
                eventName: EventName.REPORT_INSTALLATION_FAILURE,
                eventType: EventType.COUNT,
                miscEventData: {
                    startTime: startTime,
                    errorCode: errorMessage['error_code'],
                    errorMessageSystem: errorMessage['system'],
                    errorMessageDescription: errorMessage['description'],
                    errorMessageAction: errorMessage['action'],
                },
                duration_ms: Date.now() - startTime,
            });
        },
        [startTime, inputDeviceSerialNumber, inputVin],
    );

    const onStop = () => {
        setFormState(OnboardingFormState.START_CANCELLED);
    };
    const emailAddress = useSelector((state: any) => state.dynamicConfig.feedbackEmail);

    return (
        <div>
            <FailureModal
                visible={showFailureModal}
                setVisible={setShowFailureModal}
                inputVin={inputVin}
                deviceInfo={deviceInformation}
                setErrorMessage={onReportFailureCallBack}
            />
            <Container>
                <div>
                    <div
                        style={{
                            fontWeight: 'bold',
                            fontSize: '2em',
                            marginBottom: '20px',
                        }}
                    >
                        Installation App
                    </div>
                </div>
                <Alert statusIconAriaLabel="Warning" type="warning" dismissible={true}>
                    Do not email Amazon for U9s - please follow the updated troubleshooting guide.
                </Alert>
                <CheckDeviceInfo
                    checkDeviceMode={CheckDeviceMode.DEVICE_AND_VIN_CHECK}
                    initialVin={inputVin}
                    initialDeviceSerialNumber={formStoredDeviceId}
                    onSubmit={onSubmitForm}
                    onClear={onClear}
                    onStop={onStop}
                    submitButtonString={'Start Installation'}
                    submitButtonLoading={formState === OnboardingFormState.IN_PROGRESS}
                    validInputs={deviceInformation && deviceInformation['validInputs']}
                />
                {formState === OnboardingFormState.IN_PROGRESS ? (
                    <ProgressBar
                        value={progressPercentage}
                        additionalInfo="This can take up to 15 minutes"
                        label="Installing...Please keep this page open and keep vehicle ignition on"
                    />
                ) : (
                    <div />
                )}
                <SpaceBetween size={'s'}>
                    {formState >= OnboardingFormState.FINISHED_SUCCESS ? (
                        <div>
                            <DeviceInformation
                                input_vin={inputVin}
                                input_device={inputDeviceSerialNumber}
                                device_information={deviceInformation}
                            />
                            <DefectInformation
                                defect_information={defectInformation}
                                hide_header={true}
                                device_id={inputDeviceSerialNumber}
                            />
                        </div>
                    ) : (
                        <div>
                            <DefectInformation
                                defect_information={defectInformation}
                                hide_header={!defectInformation}
                                device_id={inputDeviceSerialNumber}
                            />
                        </div>
                    )}
                    {formState === OnboardingFormState.FAILURE_REPORTED && errorMessage && (
                        <Alert
                            dismissAriaLabel="Close alert"
                            type="error"
                            header={
                                errorMessage['error_code'] +
                                ' ' +
                                errorMessage['system'] +
                                ': ' +
                                errorMessage['description']
                            }
                        >
                            Action: {errorMessage['action']}
                        </Alert>
                    )}
                    {formState === OnboardingFormState.FINISHED_FAILURE && (
                        <Button
                            onClick={onReportFailure}
                            ariaLabel="Report Failure"
                            iconAlign="right"
                            iconName="external"
                        >
                            Report failure code to Amazon
                        </Button>
                    )}
                </SpaceBetween>
            </Container>
        </div>
    );
};

export default OnboardingForm;
