import '@amzn/awsui-global-styles/polaris.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import NavBar from './components/navBar';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import { PersistGate } from 'redux-persist/integration/react';
import { AppConfigContextProvider } from './config/appConfigContext';

document.body.classList.add('awsui-visual-refresh');

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <AppConfigContextProvider>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <div>
                        <NavBar />
                        <App />
                    </div>
                </PersistGate>
            </Provider>
        </AppConfigContextProvider>
    </React.StrictMode>,
);
