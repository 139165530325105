import { Header, Button, SpaceBetween, Spinner, StatusIndicator } from '@amzn/awsui-components-react';
import * as React from 'react';
import { StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator/internal';
import Webcam from 'react-webcam';

type Props = {
    input_vin: string;
    device_information: any;
    images: string[];
    showCamera: boolean;
    webcamRef: React.RefObject<Webcam>;
    onAddImage: () => void;
    onCapturePhoto?: () => void;
    onDeleteImage: (index: number) => void;
    uploadStatuses: boolean[];
};

const STATUS_CODE = 'Current Status Codes: ';

export default ({
    input_vin,
    device_information,
    images,
    showCamera,
    webcamRef,
    onAddImage,
    onCapturePhoto,
    onDeleteImage,
    uploadStatuses,
}: Props) => {
    if (!device_information) {
        return (
            <>
                <Header variant="h3">
                    Loading Information for&nbsp;
                    {input_vin}
                </Header>
                <Spinner size="large" />
            </>
        );
    }
    const device_id = device_information['vinAssociatedDeviceId'];

    const is_device_healthy = device_information['deviceHealthy'];
    var statusCodes =
        device_information['serviceStatusCodes'] && device_information['serviceStatusCodes']['latestStatusCodes'];
    var serviceNotes =
        device_information['serviceInformation'] && device_information['serviceInformation']['serviceNotes'];

    let device_health_Status: StatusIndicatorProps.Type;
    let status_text;

    if (!serviceNotes) {
        serviceNotes = 'reason unkown';
    }

    if (is_device_healthy) {
        device_health_Status = 'success';
        status_text = `Device Healthy`;
    } else {
        device_health_Status = 'info';
        if (serviceNotes && serviceNotes.trim() !== '') {
            const notesArray = serviceNotes
                .split(';')
                .map((note: string) => note.trim())
                .filter((note: string) => note !== '');
            if (notesArray.length > 0) {
                status_text = (
                    <>
                        Device unhealthy:
                        <ol style={{ listStyle: 'decimal', paddingLeft: '4rem', margin: '0' }}>
                            {notesArray.map((note: string, index: number) => (
                                <li key={index} style={{ color: 'red' }}>
                                    {note}
                                </li>
                            ))}
                        </ol>
                    </>
                );
            }
        }
    }

    const association_text = `Vehicle using Device ${device_id}.`;

    return (
        <div>
            <SpaceBetween direction="vertical" size="xxxs">
                <Header variant="h3">{input_vin} Service Info</Header>
                <StatusIndicator type={device_health_Status}>{status_text}</StatusIndicator>
                <Header variant="h3">Associated Device Info</Header>
                <StatusIndicator type={device_health_Status}>{association_text}</StatusIndicator>
                {statusCodes && (
                    <StatusIndicator type={device_health_Status}>{`${STATUS_CODE}: ${statusCodes}`}</StatusIndicator>
                )}
                <div style={{ marginTop: '20px' }}>
                    <Button onClick={onAddImage}>Add New Pictures</Button>
                </div>
                {showCamera && (
                    <div>
                        <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" width={'100%'} />
                        <div>
                            <Button onClick={onCapturePhoto}>Capture Photo</Button>
                        </div>
                    </div>
                )}
                {images.length > 0 && (
                    <div style={{ marginTop: '20px' }}>
                        <Header>Captured Pictures</Header>
                        <div>
                            {images.map((image, index) => (
                                <div key={index} style={{ marginTop: '10px' }}>
                                    <img
                                        src={image}
                                        alt={`captured ${index}`}
                                        style={{ width: '100%', maxWidth: '600px' }}
                                    />
                                    <div style={{ flex: 1 }}>
                                        {uploadStatuses[index] ? (
                                            <div style={{ color: 'green' }}>✓ Uploaded</div>
                                        ) : (
                                            <div>Uploading...</div>
                                        )}
                                    </div>
                                    <div>
                                        <Button onClick={() => onDeleteImage(index)}>Delete</Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </SpaceBetween>
        </div>
    );
};
