import React, { useState, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import { uploadPictures } from '../services/uploadPictures';
import { Button } from '@amzn/awsui-components-react';

// Interface for ImageCapture component props
interface ImageCaptureProps {
    vin: string;
    device_information: any;
    webcamRef: React.RefObject<Webcam>;
    captureImage: () => void;
    switchCamera: () => void;
    facingMode: 'user' | 'environment';
    capturedImages: string[];
    deleteImage: (index: number) => void;
    uploadStatuses: boolean[];
}

// hook for image capture
export const useImageCapture = (vin: string, deviceId: string) => {
    const [capturedImagesBeforeRepair, setCapturedImagesBeforeRepair] = useState<string[]>([]);
    const [capturedImagesAfterRepair, setCapturedImagesAfterRepair] = useState<string[]>([]);
    const [uploadStatusesBeforeRepair, setUploadStatusesBeforeRepair] = useState<boolean[]>([]);
    const [uploadStatusesAfterRepair, setUploadStatusesAfterRepair] = useState<boolean[]>([]);
    const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user');
    const webcamRef = useRef<Webcam>(null);
    const [showCamera, setShowCamera] = useState<boolean>(false);

    const switchCamera = useCallback(() => {
        setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
    }, []);

    const captureImage = useCallback(
        async (stepName: string, isBefore: boolean) => {
            const setCapturedImage = isBefore ? setCapturedImagesBeforeRepair : setCapturedImagesAfterRepair;
            const setUploadStatuses = isBefore ? setUploadStatusesBeforeRepair : setUploadStatusesAfterRepair;

            if (webcamRef.current) {
                const imageSrc = webcamRef.current.getScreenshot();
                if (imageSrc) {
                    setCapturedImage((prevImages) => [...prevImages, imageSrc]);
                    setUploadStatuses((prevStatuses) => [...prevStatuses, false]);
                    try {
                        // Await the upload function
                        const uploadResult: boolean = await uploadPictures(vin, deviceId, stepName, imageSrc);

                        // Update the status for the latest image
                        setUploadStatuses((prevStatuses) =>
                            prevStatuses.map((status, index) =>
                                index === prevStatuses.length - 1 ? uploadResult : status,
                            ),
                        );
                    } catch (error) {
                        console.error('Error uploading image:', error);
                        // Set status to false if there is an error
                        setUploadStatuses((prevStatuses) =>
                            prevStatuses.map((status, index) => (index === prevStatuses.length - 1 ? false : status)),
                        );
                    }

                    setShowCamera(false);
                }
            }
        },
        [vin, deviceId],
    );

    const deleteImageBeforeRepair = (index: number) => {
        setCapturedImagesBeforeRepair((prevImages) => prevImages.filter((_, i) => i !== index));
        setUploadStatusesBeforeRepair((prevStatuses) => prevStatuses.filter((_, i) => i !== index));
    };

    const deleteImageAfterRepair = (index: number) => {
        setCapturedImagesAfterRepair((prevImages) => prevImages.filter((_, i) => i !== index));
        setUploadStatusesAfterRepair((prevStatuses) => prevStatuses.filter((_, i) => i !== index));
    };

    const handleAddPhoto = () => {
        setShowCamera(true); // Show camera
    };

    return {
        webcamRef,
        capturedImagesBeforeRepair,
        capturedImagesAfterRepair,
        capturePicturesBeforeRepair: () => captureImage('before_repair_picture', true),
        capturePicturesAfterRepair: () => captureImage('after_repair_picture', false),
        switchCamera,
        facingMode,
        deleteImageBeforeRepair,
        deleteImageAfterRepair,
        handleAddPhoto,
        showCamera,
        setShowCamera,
        uploadStatusesAfterRepair,
        uploadStatusesBeforeRepair,
    };
};

// ImageCapture component
const ImageCapture: React.FC<ImageCaptureProps> = ({
    webcamRef,
    captureImage,
    switchCamera,
    facingMode,
    capturedImages,
    deleteImage,
    uploadStatuses,
}) => {
    return (
        <div style={{ display: 'inline-block', marginBottom: '20px' }}>
            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode }}
                width={'100%'}
            />
            <div>
                <Button onClick={captureImage}>Capture</Button>
                <Button onClick={switchCamera}>
                    {facingMode === 'user' ? 'Switch to Rear Camera' : 'Switch to Front Camera'}
                </Button>
            </div>
            <div>
                {capturedImages.map((image, index) => (
                    <div key={index} style={{ marginTop: '20px' }}>
                        <img src={image} alt={`captured ${index}`} style={{ width: '100%' }} />
                        <div style={{ flex: 1 }}>
                            {uploadStatuses[index] ? (
                                <div style={{ color: 'green' }}>✓ Uploaded</div>
                            ) : (
                                <div>Uploading...</div>
                            )}
                        </div>
                        <Button onClick={() => deleteImage(index)}>Delete</Button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageCapture;
