import React, { useState, useEffect } from 'react';
import { Form, SpaceBetween, Multiselect, Button, Input, Header, Select } from '@amzn/awsui-components-react';
import { useSelector } from 'react-redux';

type Props = {
    inputVin: string;
    deviceInfo: any;
    callback: (error: any, vin: string) => void;
};

type MultiselectOption = { label: string; value: string };

const ReportRepairFailure = ({ inputVin, deviceInfo, callback }: Props) => {
    const [vin, setVin] = useState(inputVin);
    const [selectedErrorCode, setSelectedErrorCode] = useState<MultiselectOption[]>([]);
    const [errorText, setErrorText] = useState('');
    const [isVinValid, setIsVinValid] = useState<boolean | null>(null);
    const multiselectOptions = useSelector((state: any) => state.multiselectOptionsConfig);
    const [isDropdownEnabled, setIsDropdownEnabled] = useState(false);
    const [showOtherReasonInput, setShowOtherReasonInput] = useState(false);
    const [otherReason, setOtherReason] = useState('');
    const [otherErrorText, setOtherErrorText] = useState('');
    const isButtonDisabled = selectedErrorCode.length === 0;

    const validateVin = (vin: string) => {
        if (!vin || vin.length <= 0) {
            setErrorText('Vehicle VIN is required');
            setIsVinValid(false);
        } else if (!(vin.length === 17 && vin.toUpperCase() === vin)) {
            setErrorText('Vehicle VIN should be 17 characters in length & uppercase');
            setIsVinValid(false);
        } else {
            setErrorText('');
            setIsVinValid(true);
            setIsDropdownEnabled(true);
        }
    };

    useEffect(() => {
        validateVin(vin);
    }, [vin]);

    const onSubmit = (e: any) => {
        e.preventDefault();

        if (selectedErrorCode.length > 0 && isVinValid) {
            if (
                selectedErrorCode.some((selectedOption) => selectedOption.value === 'other_reasons') &&
                !otherReason.trim()
            ) {
                setOtherErrorText('Please specify the reason');
                return; // Exit early
            }
            // Construct and handle the error message as needed
            const errorMessages = selectedErrorCode.map((selectedOption) => {
                if (selectedOption.value === 'other_reasons') {
                    // Check if "Other Reasons" option is selected and return the value from the input field
                    return {
                        label: multiselectOptions.unableToRepairOptions.find(
                            (option: MultiselectOption) => option.value === selectedOption.value,
                        ).label,
                        value: 'other reasons: ' + otherReason.trim(),
                    };
                }
                return {
                    label: multiselectOptions.unableToRepairOptions.find(
                        (option: MultiselectOption) => option.value === selectedOption.value,
                    ).label,
                    value: selectedOption.value,
                };
            });

            callback(errorMessages, vin);
            console.log('onSubmit function called');
        }
    };

    return (
        <div>
            <form onSubmit={onSubmit}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary" disabled={isButtonDisabled}>
                                Report Reasons
                            </Button>
                        </SpaceBetween>
                    }
                >
                    <Header variant="h3">Enter Vehicle VIN</Header>
                    <Input
                        value={vin}
                        onChange={(e) => {
                            setVin(e.detail.value);
                            validateVin(e.detail.value);
                        }}
                    />
                    {errorText ? <div style={{ color: 'red' }}>{errorText}</div> : null}

                    <Header variant="h3">Please choose reasons for not being able to complete repair</Header>
                    <Multiselect
                        selectedOptions={selectedErrorCode}
                        onChange={({ detail }) => {
                            const selectedOptions = detail.selectedOptions as MultiselectOption[];
                            setSelectedErrorCode(selectedOptions);

                            // Check if "Other Reasons" is selected
                            const isOtherReasonSelected = selectedOptions.some(
                                (option) => option.value === 'other_reasons',
                            );
                            setShowOtherReasonInput(isOtherReasonSelected);
                        }}
                        options={multiselectOptions.unableToRepairOptions}
                        filteringType="auto"
                        placeholder="Choose reason for not able to repair"
                        selectedAriaLabel="Selected"
                        disabled={!isDropdownEnabled}
                    />

                    {showOtherReasonInput && (
                        <div style={{ marginTop: '16px' }}>
                            <Input
                                value={otherReason}
                                onChange={(e) => {
                                    setOtherReason(e.detail.value);
                                    // Reset error message when input changes
                                    setOtherErrorText('');
                                }}
                                placeholder="Please specify the reason"
                            />
                            {otherErrorText && <div style={{ color: 'red' }}>{otherErrorText}</div>}
                        </div>
                    )}
                </Form>
            </form>
        </div>
    );
};

export default ReportRepairFailure;
