import * as React from 'react';
import { Button, SpaceBetween, Header, Container, FormField, Input, Form, Modal } from '@amzn/awsui-components-react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoForgotPassword, CognitoNewPassword } from '../../services/authenticationService';
import { useCookies } from 'react-cookie';
import { AppConfigContext } from '../../config/appConfigContext';
import PasswordChecklist from 'react-password-checklist';
import Icon from '@amzn/awsui-components-react/polaris/icon';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [formSubmit, setFormSubmit] = useState(false);
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [modalSubmit, setModalSubmit] = useState(false);
    const [code, setCode] = useState('');
    const [formError, setFormError] = useState(false);
    const [modalError, setModalError] = useState(false);
    const { config }: { config: any } = useContext(AppConfigContext);
    const cognito_config = config.cognito_config;
    const navigate = useNavigate();

    const onSubmitForm = (formData: any) => {
        formData.preventDefault();
        setFormSubmit(true);
        //make sure all fields are present
        if (email) {
            //call cognito here
            CognitoForgotPassword(
                {
                    userPoolId: cognito_config.user_pool_id,
                    clientId: cognito_config.client_id,
                    email: email,
                },
                function (success: boolean, value: any) {
                    if (success) {
                        setFormError(false);
                        setOpenModal(true);
                    } else {
                        setFormError(true);
                    }
                },
            );
        }
    };

    const onSubmitModal = (formData: any) => {
        formData.preventDefault();
        setModalSubmit(true);
        if (code && password && verifyPassword == password) {
            //we will call cognito here
            CognitoNewPassword(
                {
                    userPoolId: cognito_config.user_pool_id,
                    clientId: cognito_config.client_id,
                    email: email,
                    code: code,
                    newPassword: password,
                },
                function (success: boolean, value: any) {
                    if (success) {
                        setModalError(false);
                        navigate('/login');
                    } else {
                        setModalError(true);
                    }
                },
            );
        }
    };
    return (
        <Container>
            <Modal visible={openModal} onDismiss={() => setOpenModal(false)}>
                <form onSubmit={onSubmitModal}>
                    <Form
                        errorText={modalError && 'Issue resetting password. Please try again.'}
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary">Reset Password</Button>
                            </SpaceBetween>
                        }
                    >
                        <Header variant="h2">
                            Set new password. You will be redirected to login after password is confirmed.
                        </Header>
                        <SpaceBetween direction="vertical" size="xs">
                            <FormField
                                label="Enter verification code sent to your email"
                                errorText={modalSubmit && !code && 'Please Enter Confirmation Code'}
                            >
                                <Input value={code} onChange={({ detail }) => setCode(detail.value)} />
                            </FormField>
                            <FormField
                                label="New Password"
                                errorText={modalSubmit && !password && 'Please enter new password'}
                            >
                                <Input
                                    value={password}
                                    type="password"
                                    onChange={({ detail }) => setPassword(detail.value)}
                                />
                            </FormField>
                            <FormField
                                label="Re-enter new Password"
                                errorText={
                                    modalSubmit && verifyPassword != password && 'Please make sure passwords match'
                                }
                            >
                                <Input
                                    value={verifyPassword}
                                    type="password"
                                    onChange={({ detail }) => setVerifyPassword(detail.value)}
                                />
                                <PasswordChecklist
                                    rules={['minLength', 'specialChar', 'number', 'capital', 'match', 'lowercase']}
                                    minLength={12}
                                    value={password}
                                    valueAgain={verifyPassword}
                                    onChange={(isValid) => {}}
                                    iconComponents={{
                                        ValidIcon: (
                                            <div>
                                                &nbsp; <Icon name="check" size="medium" variant="success" />
                                                &nbsp;{' '}
                                            </div>
                                        ),
                                        InvalidIcon: (
                                            <div>
                                                {' '}
                                                &nbsp; <Icon
                                                    name="status-negative"
                                                    size="medium"
                                                    variant="error"
                                                />{' '}
                                                &nbsp;
                                            </div>
                                        ),
                                    }}
                                />
                            </FormField>
                        </SpaceBetween>
                    </Form>
                </form>
            </Modal>
            <form onSubmit={onSubmitForm}>
                <Form
                    errorText={formError && 'Unable to find email. Please try again'}
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary">Reset Password</Button>
                        </SpaceBetween>
                    }
                >
                    <Header variant="h2">Enter email to reset password</Header>
                    <SpaceBetween direction="vertical" size="xs">
                        <FormField label="Email" errorText={formSubmit && !email && 'Please enter email'}>
                            <Input value={email} onChange={({ detail }) => setEmail(detail.value.toLowerCase())} />
                        </FormField>
                    </SpaceBetween>
                </Form>
            </form>
        </Container>
    );
};

export default ForgotPassword;
