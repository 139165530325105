import { getApiGatwayUrl } from './deviceStatusHandler';

const apigClientFactory = require('aws-api-gateway-client').default;
import { v4 as uuidv4 } from 'uuid';
import { store } from '../app/store';

export enum EventName {
    // Installation
    DEVICE_INSTALLATION = 'DEVICE_INSTALLATION',
    REPORT_INSTALLATION_FAILURE = 'REPORT_INSTALLATION_FAILURE',
    CHECK_STATUS = 'CHECK_STATUS',
    MANUAL_VIN_MODIFICATION = 'MANUAL_VIN_MODIFICATION',
    MANUAL_DEVICE_SERIAL_NUMBER_MODIFICATION = 'MANUAL_DEVICE_SERIAL_NUMBER_MODIFICATION',
    FORCE_START_DEPLOYMENT = 'FORCE_START_DEPLOYMENT',
    REPAIR_DEVICE = 'REPAIR_DEVICE',
    REPAIR_DEVICE_STATE = 'REPAIR_DEVICE_STATE',
    REPAIR_ACTIONS_TAKEN = 'REPAIR_ACTIONS_TAKEN',

    // App Functionality
    VIN_DECODE_FROM_CAMERA = 'VIN_DECODE_FROM_CAMERA',
    SERIAL_NUMBER_DECODE_FROM_CAMERA = 'SERIAL_NUMBER_DECODE_FROM_CAMERA',
    LOGIN_ATTEMPT = 'LOGIN_ATTEMPT',

    // Authentication
    PASSWORD_RESET = 'PASSWORD_RESET',
    MANUAL_SIGN_OUT = 'MANUAL_SIGN_OUT',
    COGNITO_TOKEN_REFRESH = 'COGNITO_TOKEN_REFRESH',
}

export enum EventType {
    START = 'START',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    DURATION = 'DURATION',
    COUNT = 'COUNT',
    FAILED_VALIDATION = 'FAILED_VALIDATION',
}

export type Event = {
    // device information
    readonly deviceId?: string;
    readonly vin?: string;

    // event data
    readonly eventName: EventName;
    readonly eventType: EventType;
    readonly duration_ms?: number;
    readonly miscEventData?: {};

    // user information
    readonly cookies?: {
        group: any;
        username: any;
        'custom:company': any;
        jwt: any;
    };
};

export const logMetricEvent = (event: Event) => {
    const client = apigClientFactory.newClient({
        invokeUrl: getApiGatwayUrl(),
    });
    let cookies;
    if (event.cookies) {
        console.log('No token present');
        cookies = event.cookies;
    } else {
        cookies = store.getState().userData.userInfo;
    }
    client.invokeApi(
        {},
        '/log_onboarding',
        'POST',
        {
            queryParams: {
                // we will get userid and role from token in lambda
                clientEventId: uuidv4(),
                deviceId: event.deviceId || '',
                vin: event.vin || '',
                eventName: event.eventName,
                eventType: event.eventType,
                duration_ms: event.duration_ms ? event.duration_ms : 0,
                eventData: event.miscEventData ? JSON.stringify(event.miscEventData) : '',
                clientEventTime: Date.now(),
                userId: cookies!.username,
                group: cookies!.group,
                company: cookies!['custom:company'],
            },
            headers: {
                Authorization: cookies!.jwt,
            },
        },
        null,
    );
};
