const apigClientFactory = require('aws-api-gateway-client').default;
import { store } from '../app/store';
import { getApiGatwayUrl } from './deviceStatusHandler';

export default (setResult: (success: boolean, r: any) => void) => {
    // extract endpoint from config
    const client = apigClientFactory.newClient({
        invokeUrl: getApiGatwayUrl(),
        retries: 3,
    });

    client
        .invokeApi(
            {},
            '/dynamicconfig',
            'GET',
            {
                headers: {
                    Authorization: store.getState().userData.userInfo!.jwt,
                },
            },
            null,
        )
        .then((response: any) => {
            setResult(true, JSON.parse(response.data));
        })
        .catch((response: any) => {
            setResult(false, response);
        });
};
