import { Header, StatusIndicator } from '@amzn/awsui-components-react';
import * as React from 'react';
import { StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator/internal';

type Props = {
    input_vin: string;
    input_device: string;
    device_information: any;
};

const STATUS_INDICATOR_ERROR: StatusIndicatorProps.Type = 'error';
const STATUS_CODE = 'Status Code';

export default ({ input_vin, input_device, device_information }: Props) => {
    const do_vins_match = device_information['vin'] === input_vin;
    const do_device_ids_match =
        device_information['vinAssociatedDeviceId'] !== null &&
        device_information['vinAssociatedDeviceId'] === input_device;

    const is_device_onboarded = device_information['platformDeploymentTriggeredTime'] != null;
    const statusCodes = device_information['latestStatusCodes'];

    var vin_status_type: StatusIndicatorProps.Type = 'error';
    var device_onboarded_status_Type: StatusIndicatorProps.Type = 'error';
    const device_check_status_type: StatusIndicatorProps.Type = 'error';

    let device_text = `Device Check Failed`;
    let vin_text = 'Vin Check Failed';
    var onboarding_text = 'Installation Failed';

    const shouldShowStatusCode = statusCodes && statusCodes.length > 0;
    if (do_vins_match && do_device_ids_match) {
        vin_status_type = 'success';
        vin_text = 'Vin Check Success';
        if (is_device_onboarded) {
            device_onboarded_status_Type = 'success';
            onboarding_text = 'Installation Completed';
        }
    } else if (!do_vins_match) {
        vin_text = `${vin_text}: VIN ${input_vin} associated with Device ${device_information['vinAssociatedDeviceId']}`;
    }
    if (!do_device_ids_match) {
        device_text = `${device_text}: Device ${input_device} associated with VIN ${device_information['vin']}`;
    }

    return (
        <div>
            <Header variant="h3">
                Device Information for&nbsp;
                {device_information['deviceId']}
            </Header>
            <ul>
                <StatusIndicator type={vin_status_type}>{vin_text}</StatusIndicator>
                <p />
                {!do_device_ids_match && (
                    <>
                        <StatusIndicator type={device_check_status_type}>{device_text}</StatusIndicator>
                        <p />
                    </>
                )}
                <StatusIndicator type={device_onboarded_status_Type}>{onboarding_text}</StatusIndicator>
                <p />
                {shouldShowStatusCode && (
                    <StatusIndicator type={STATUS_INDICATOR_ERROR}>{`${STATUS_CODE}: ${statusCodes}`}</StatusIndicator>
                )}
            </ul>
        </div>
    );
};
