import React, { useState } from 'react';
import VinInput from '../components/VinInput';
import { Header, Alert, Button, SpaceBetween, Container } from '@amzn/awsui-components-react';
import deviceRepairStatusHandler from '../services/deviceRepairStatusHandler';
import { useNavigate } from 'react-router-dom';

const VinInformationPage = () => {
    const [vin, setVin] = useState('');
    const [loading, setLoading] = useState(false);
    const [isVinValid, setIsVinValid] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [nextButtonClicked, setNextButtonClicked] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    //const [errorAlert, setErrorAlert] = useState('');
    const navigate = useNavigate();

    const validateVin = (vin: string) => {
        if (!vin || vin.length <= 0) {
            setErrorText('Vehicle VIN is required');
            setIsVinValid(false);
        } else if (!(vin.length === 17 && vin.toUpperCase() === vin)) {
            setErrorText('Vehicle VIN should be 17 characters in length & uppercase');
            setIsVinValid(false);
        } else {
            setErrorText('');
            setIsVinValid(true);
        }
    };

    const handleVinChange = (newVin: string) => {
        setVin(newVin);
        validateVin(newVin);
    };

    const checkVinExistence = () => {
        setLoading(true);
        // Use deviceStatusHandler to check if the VIN exists
        deviceRepairStatusHandler(vin, true, (success, data) => {
            if (success) {
                if (data.isRepairFlow) {
                    // VIN exists, navigate to the repair page
                    navigate(`/repair?vin=${vin}`);
                } else {
                    // VIN is invalid, navigate to the onboarding page
                    navigate(`/onboarding?vin=${vin}`);
                }
            } else {
                // API call failed, show an error alert
                setShowErrorAlert(true);
                const errorAlert = 'Error Occured while checking vin';
                navigate(`/?showErrorAlert=true&errorAlert=${encodeURIComponent(errorAlert)}`);
            }
        });
    };

    return (
        <Container>
            <Header variant="h1">Enter Vehicle Information</Header>
            <SpaceBetween size={'xl'}>
                <VinInput setVin={handleVinChange} vin={vin} isVinValid={isVinValid} />
            </SpaceBetween>
            {errorText ? <div style={{ color: 'red' }}>{errorText}</div> : null}
            <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                <Button onClick={checkVinExistence} loading={loading}>
                    Submit
                </Button>
            </div>
        </Container>
    );
};

export default VinInformationPage;
