import React, { useEffect, useState } from 'react';
import { setApiGatwayId } from '../services/deviceStatusHandler';

export type ContextType = {
    config: {};
};
export const AppConfigContext = React.createContext<ContextType>({
    config: {},
});

export const AppConfigContextProvider = (props: any) => {
    const [config, setConfig] = useState({});

    useEffect(() => {
        fetch('config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then(
                (response) => {
                    return response.json();
                },
                (err) => console.log(err),
            )
            .then((response) => {
                setConfig(response);
                // TODO: move this to redux so we don't need global variables
                setApiGatwayId(response);
            });
    }, [setConfig]);

    const value = { config };
    return <AppConfigContext.Provider value={value}>{props.children}</AppConfigContext.Provider>;
};
