import { createSlice } from '@reduxjs/toolkit';
const result: any[] = [];
export const installationDataSlice = createSlice({
    name: 'installationData',
    initialState: {
        installs: result,
    },
    reducers: {
        addInstall: (state, param) => {
            state.installs.unshift(param.payload);
        },
        expireInstalls: (state) => {
            const newArr = state.installs.filter((obj) => Date.now() / 1000 - obj.time < 604800);
            state.installs = newArr;
        },
        clearData: (state) => {
            state.installs = [];
        },
    },
});

export const installationDataReducer = installationDataSlice.reducer;
export const { addInstall, expireInstalls, clearData } = installationDataSlice.actions;
