import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Alert,
    AppLayout,
    Container,
    Header,
    Multiselect,
    ProgressBar,
    SpaceBetween,
    Wizard,
    Button,
} from '@amzn/awsui-components-react';
import ServiceInformation from '../../components/serviceInformation';
import VinInput from '../../components/VinInput';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import deviceRepairStatusHandler from '../../services/deviceRepairStatusHandler';
import { POSSIBLE_ERROR_CODES } from '../../components/reportFailure';
import { useSelector } from 'react-redux';
import { debugAndFixDevice } from '../../services/debugAndFixDevice';
import { EventName, EventType, logMetricEvent } from '../../services/eventService';
import FailureRepairModal from '../../components/failureRepairModal';
import Webcam from 'react-webcam';
import ImageCapture, { useImageCapture } from '../../components/repairPictures';

enum FixSteps {
    TAKE_PICTURE_BEFORE = 0,
    REPAIR_INFO_PAGE = 1,
    REPORT_DEVICE_STATE = 2,
    REPORT_ACTIONS_TAKEN = 3,
    WAIT_FOR_CLOUD_SUCCESS = 4,
    SUCCESS = 5,
    TAKE_PICTURE_AFTER = 6,
    DONE = 7,
}

const RepairForm = () => {
    const [startTime, setStartTime] = useState(0);
    const [data, setData] = useState();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const vin = params.get('vin') || '';
    const deviceId = data ? data['vinAssociatedDeviceId'] : '';
    const [activeStepIndex, setActiveStepIndex] = useState(FixSteps.TAKE_PICTURE_BEFORE);
    const multiselectOptions = useSelector((state: any) => state.multiselectOptionsConfig);
    const [selectedActionsTaken, setSelectedActionsTaken] = useState([]);
    const [selectedDeviceState, setSelectedDeviceState] = useState([]);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [isVinValid, setIsVinValid] = useState(true);
    const [isDeviceStateValid, setIsDeviceStateValid] = useState(true);
    const [isActionsTakenValid, setIsActionsTakenValid] = useState(true);
    const [success, setSuccess] = useState(false);
    const [deviceGotReplaced, setDeviceGotReplaced] = useState(false);
    const debugAndFixCalledRef = useRef(false);
    const [isFailureModalOpen, setFailureModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState([]);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const navigate = useNavigate();

    const onClear = useCallback(() => {
        setData(undefined);
        setStartTime(Date.now());
    }, [setStartTime, setData]);

    const getDurationInMs = () => {
        const maxDuration = Math.pow(2, 31) - 1;
        return Math.min(Date.now() - startTime, maxDuration);
    };

    const onServiceResult = (success: boolean, inputData: any) => {
        if (success) {
            if (inputData['validInputs']) {
                const validInputs = inputData['validInputs'];
                const validVin = validInputs['isValidVin'];
                if (!validVin) {
                    setIsVinValid(false);
                    setErrorText('Vehicle VIN is not valid in the system');
                    setActiveStepIndex((index) => index - 1);
                    return;
                } else {
                    if (!debugAndFixCalledRef.current && inputData['vinAssociatedDeviceId']) {
                        debugAndFixCalledRef.current = true;
                        debugAndFixDevice(inputData['vinAssociatedDeviceId'], true);
                        logMetricEvent({
                            deviceId: inputData['vinAssociatedDeviceId'],
                            vin: vin,
                            eventName: EventName.FORCE_START_DEPLOYMENT,
                            eventType: EventType.COUNT,
                        });
                    }
                    setIsVinValid(true);
                }
            }
            if (data && inputData['vinAssociatedDeviceId'] != data['vinAssociatedDeviceId']) {
                setProgressPercentage(50);
                setDeviceGotReplaced(true);
            }
            setData(inputData);
            // if success cancel timer
            if (inputData['deviceHealthy']) {
                setSuccess(true);
                setProgressPercentage(100);
                setLoading(false);
                setErrorText('');
                logMetricEvent({
                    vin: vin,
                    deviceId: inputData['vinAssociatedDeviceId'],
                    eventName: EventName.REPAIR_DEVICE,
                    eventType: EventType.SUCCESS,
                    miscEventData: {
                        inputData,
                    },
                    duration_ms: getDurationInMs(),
                });
            } else if (inputData['onboardingInformation']) {
                const onboardingInformation = inputData['onboardingInformation'];
                if (onboardingInformation['installerDeploymentCompletedTime']) {
                    setProgressPercentage(90);
                } else if (onboardingInformation['installerDeploymentTriggeredTime']) {
                    const serviceStatusCodes = inputData['latestStatusCodes'];
                    if (serviceStatusCodes['latestStatusCodes'] && serviceStatusCodes['latestStatusCodesTime']) {
                        // Cancel installation if we get a status code before deployment finishes
                        const latestStatusCode = serviceStatusCodes['latestStatusCodes'][0];
                        const statusCodeErrorMessage = {
                            ...POSSIBLE_ERROR_CODES[latestStatusCode],
                            error_code: latestStatusCode,
                        };
                        // new deployment has status code
                        setErrorText(`device StatusCode ${statusCodeErrorMessage}`);
                    } else {
                        setProgressPercentage(80);
                    }
                } else if (onboardingInformation['activationCompletedTime']) {
                    setProgressPercentage(70);
                } else {
                    setProgressPercentage(20);
                }
            }
        }
    };

    const onReportDeviceState = useCallback(
        (callback: () => void) => {
            if (selectedDeviceState) {
                logMetricEvent({
                    vin: vin,
                    deviceId: data && data['vinAssociatedDeviceId'],
                    eventName: EventName.REPAIR_DEVICE_STATE,
                    eventType: EventType.COUNT,
                    miscEventData: {
                        selectedDeviceState,
                    },
                    duration_ms: getDurationInMs(),
                });
                callback();
            } else {
                callback();
            }
        },
        [selectedDeviceState, data, vin, startTime],
    );

    const onReportActionsTaken = useCallback(
        (callback: () => void) => {
            if (selectedActionsTaken) {
                logMetricEvent({
                    vin: vin,
                    deviceId: data && data['vinAssociatedDeviceId'],
                    eventName: EventName.REPAIR_ACTIONS_TAKEN,
                    eventType: EventType.COUNT,
                    miscEventData: {
                        selectedActionsTaken,
                    },
                    duration_ms: getDurationInMs(),
                });
                callback();
            } else {
                callback();
            }
        },
        [selectedActionsTaken, data, vin, startTime],
    );

    const onReportFailure = useCallback((e: any) => {
        setFailureModalOpen(true);
    }, []);

    const handleRepairFailure = useCallback(
        (errorMessage: any, vinEntered: string) => {
            console.log('onCancel called with errorMessage:', errorMessage);
            setErrorMessage(errorMessage);
            if (errorMessage) {
                console.log('Callback function called', errorMessage);
                const vinToLog = FixSteps.REPAIR_INFO_PAGE ? vinEntered : vin;
                logMetricEvent({
                    vin: vinToLog,
                    deviceId: data && data['vinAssociatedDeviceId'],
                    eventName: EventName.REPAIR_DEVICE,
                    eventType: EventType.FAILURE,
                    miscEventData: {
                        errorMessage,
                    },
                    duration_ms: getDurationInMs(),
                });
                setShowErrorAlert(true);
                const errorValues = errorMessage.map((error: any) => error.value);
                const errorAlert =
                    'Repair Cancelled for vin  ' + vinToLog + ' with reasons:  ' + errorValues.join(', ');
                navigate(`/?showErrorAlert=true&errorAlert=${encodeURIComponent(errorAlert)}`);
            }
        },
        [data, vin, startTime, setErrorMessage],
    );

    useEffect(() => {
        if (activeStepIndex === FixSteps.TAKE_PICTURE_BEFORE) {
            if (vin) {
                if (!startTime) {
                    const time = Date.now();
                    setStartTime(time);
                    logMetricEvent({
                        vin: vin,
                        eventName: EventName.REPAIR_DEVICE,
                        eventType: EventType.START,
                        miscEventData: {
                            startTime: time,
                        },
                        duration_ms: 0,
                    });
                }
            }
        }
        deviceRepairStatusHandler(vin, true, onServiceResult);
        const timer = setInterval(() => {
            deviceRepairStatusHandler(vin, false, onServiceResult);
        }, 1000 * 30);
        return () => clearInterval(timer);
    }, [activeStepIndex]);

    const checkedLoadedInformation = useCallback(() => {
        if (!data) {
            setErrorText('Wait to load repair information before moving to next page');
            return false;
        } else {
            setErrorText('');
            return true;
        }
    }, [setErrorText, data]);

    const validateDeviceState = useCallback(() => {
        if (selectedDeviceState.length === 0) {
            setErrorText('Please select at least one device state option.');
            setIsDeviceStateValid(false);
            return false;
        } else {
            setErrorText('');
            setIsDeviceStateValid(true);
            return true;
        }
    }, [setErrorText, selectedDeviceState]);

    const validateActionTaken = useCallback(() => {
        if (selectedActionsTaken.length === 0) {
            setErrorText('Please select at least one action taken option.');
            setIsActionsTakenValid(false);
            return false;
        } else {
            setErrorText('');
            setIsActionsTakenValid(true);
            return true;
        }
    }, [setErrorText, selectedActionsTaken]);

    const {
        webcamRef,
        capturedImagesBeforeRepair,
        capturedImagesAfterRepair,
        capturePicturesBeforeRepair,
        capturePicturesAfterRepair,
        switchCamera,
        facingMode,
        deleteImageAfterRepair,
        deleteImageBeforeRepair,
        handleAddPhoto,
        showCamera,
        setShowCamera,
        uploadStatusesAfterRepair,
        uploadStatusesBeforeRepair,
    } = useImageCapture(vin, deviceId);

    const validateCapturedImageBeforeRepair = useCallback(() => {
        if (capturedImagesBeforeRepair.length > 0) {
            setErrorText('');
            return true;
        } else {
            setErrorText('Please capture a photo before the repair');
            return false;
        }
    }, [capturedImagesBeforeRepair, setErrorText]);

    const validateCapturedImageAfterRepair = useCallback(() => {
        if (capturedImagesAfterRepair.length > 0) {
            setErrorText('');
            return true;
        } else {
            setErrorText('Please capture a photo after the repair');
            return false;
        }
    }, [capturedImagesAfterRepair, setErrorText]);

    const onNavigate = useCallback(
        ({ detail }: any) => {
            const { reason, requestedStepIndex } = detail;

            if (reason === 'previous') {
                setActiveStepIndex(requestedStepIndex);
                return;
            } else if (reason === 'cancel') {
                setFailureModalOpen(true);
            }
            const moveNextCallback = () => {
                setLoading(false);
                setActiveStepIndex(requestedStepIndex);
            };
            switch (requestedStepIndex) {
                case FixSteps.TAKE_PICTURE_BEFORE:
                    return;
                case FixSteps.REPAIR_INFO_PAGE:
                    // Won't happen since this is the first step of the page
                    if (validateCapturedImageBeforeRepair()) {
                        moveNextCallback();
                    }
                    return;
                case FixSteps.REPORT_DEVICE_STATE:
                    if (checkedLoadedInformation()) {
                        moveNextCallback();
                    }
                    return;
                case FixSteps.REPORT_ACTIONS_TAKEN:
                    if (validateDeviceState()) {
                        setLoading(true);
                        onReportDeviceState(moveNextCallback);
                    }
                    return;
                case FixSteps.WAIT_FOR_CLOUD_SUCCESS:
                    // loading will be set to false by onServiceResult so we shouldn't pass here
                    if (validateActionTaken()) {
                        setLoading(true);
                        onReportActionsTaken(moveNextCallback);
                    }
                    return;
                case FixSteps.SUCCESS:
                    if (success) {
                        moveNextCallback();
                    } else {
                        setErrorText('Device is not yet ready. Please wait until device software is ready');
                    }
                    return;
                case FixSteps.TAKE_PICTURE_AFTER:
                    if (validateCapturedImageAfterRepair()) {
                        moveNextCallback();
                    }
                    return;
                case FixSteps.DONE:
                    navigate('/', { replace: true });
            }
        },
        [
            setActiveStepIndex,
            data,
            startTime,
            setLoading,
            success,
            setErrorText,
            checkedLoadedInformation,
            setStartTime,
            selectedDeviceState,
            selectedActionsTaken,
            setFailureModalOpen,
            setErrorMessage,
            capturedImagesBeforeRepair,
            capturedImagesAfterRepair,
        ],
    );

    const onSubmit = useCallback(() => {
        // must validate we hit success
        navigate('/');
    }, []);

    return (
        <>
            <Container>
                <div>
                    <div
                        style={{
                            fontWeight: 'bold',
                            fontSize: '2em',
                            marginBottom: '20px',
                        }}
                    >
                        Repair App
                    </div>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                    <AppLayout
                        contentType="wizard"
                        navigationHide={true}
                        // splitPanelOpen={false}
                        toolsHide={true}
                        content={
                            <Wizard
                                i18nStrings={{
                                    stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                                        `Step ${stepNumber} of ${stepsCount}`,
                                    skipToButtonLabel: (step, stepNumber) => `Skip to ${step.title}`,
                                    navigationAriaLabel: 'Steps',
                                    cancelButton: 'Cannot Repair',
                                    previousButton: 'Previous',
                                    nextButton: 'Next',
                                    submitButton: 'Done',
                                    optional: 'optional',
                                }}
                                onCancel={onReportFailure}
                                onNavigate={onNavigate}
                                onSubmit={onSubmit}
                                activeStepIndex={activeStepIndex}
                                isLoadingNextStep={loading}
                                steps={[
                                    {
                                        title: 'Take a Picture of the problem',
                                        errorText,
                                        content: (
                                            <>
                                                <ImageCapture
                                                    vin={vin}
                                                    device_information={data && data['vinAssociatedDeviceId']}
                                                    webcamRef={webcamRef}
                                                    captureImage={capturePicturesBeforeRepair}
                                                    switchCamera={switchCamera}
                                                    facingMode={facingMode}
                                                    capturedImages={capturedImagesBeforeRepair}
                                                    deleteImage={deleteImageBeforeRepair}
                                                    uploadStatuses={uploadStatusesBeforeRepair}
                                                />
                                            </>
                                        ),
                                    },
                                    {
                                        title: 'Service Request Information',
                                        errorText,
                                        content: (
                                            <>
                                                <ServiceInformation
                                                    input_vin={vin}
                                                    device_information={data}
                                                    images={capturedImagesBeforeRepair}
                                                    showCamera={showCamera}
                                                    webcamRef={webcamRef}
                                                    onAddImage={handleAddPhoto}
                                                    onCapturePhoto={capturePicturesBeforeRepair}
                                                    onDeleteImage={deleteImageBeforeRepair}
                                                    uploadStatuses={uploadStatusesBeforeRepair}
                                                />
                                            </>
                                        ),
                                    },
                                    {
                                        title: 'Device State',
                                        errorText,
                                        content: (
                                            <>
                                                <Header variant="h3">Report what you see</Header>
                                                <SpaceBetween size={'xl'}>
                                                    <Multiselect
                                                        selectedOptions={selectedDeviceState}
                                                        onChange={({ detail }) =>
                                                            setSelectedDeviceState(detail.selectedOptions as any)
                                                        }
                                                        deselectAriaLabel={(e) => `Remove ${e.label}`}
                                                        options={multiselectOptions.deviceStateOptions}
                                                        expandToViewport={true}
                                                        filteringType="auto"
                                                        placeholder="Choose 1 or more options"
                                                        selectedAriaLabel="Selected"
                                                        virtualScroll={true}
                                                    />
                                                    <ServiceInformation
                                                        input_vin={vin}
                                                        device_information={data}
                                                        images={capturedImagesBeforeRepair}
                                                        showCamera={showCamera}
                                                        webcamRef={webcamRef}
                                                        onAddImage={handleAddPhoto}
                                                        onCapturePhoto={capturePicturesBeforeRepair}
                                                        onDeleteImage={deleteImageBeforeRepair}
                                                        uploadStatuses={uploadStatusesBeforeRepair}
                                                    />
                                                </SpaceBetween>
                                            </>
                                        ),
                                    },
                                    {
                                        title: 'Actions Taken',
                                        errorText,
                                        content: (
                                            <>
                                                <Header variant="h3">Report Repair Actions Taken</Header>
                                                <SpaceBetween size={'xl'}>
                                                    <Multiselect
                                                        selectedOptions={selectedActionsTaken}
                                                        onChange={({ detail }) =>
                                                            setSelectedActionsTaken(detail.selectedOptions as any)
                                                        }
                                                        deselectAriaLabel={(e) => `Remove ${e.label}`}
                                                        options={multiselectOptions.repairActionOptions}
                                                        expandToViewport={true}
                                                        filteringType="auto"
                                                        placeholder="Choose 1 or more options"
                                                        selectedAriaLabel="Selected"
                                                        virtualScroll={true}
                                                    />
                                                    <ServiceInformation
                                                        input_vin={vin}
                                                        device_information={data}
                                                        images={capturedImagesBeforeRepair}
                                                        showCamera={showCamera}
                                                        webcamRef={webcamRef}
                                                        onAddImage={handleAddPhoto}
                                                        onCapturePhoto={capturePicturesBeforeRepair}
                                                        onDeleteImage={deleteImageBeforeRepair}
                                                        uploadStatuses={uploadStatusesBeforeRepair}
                                                    />
                                                </SpaceBetween>
                                            </>
                                        ),
                                    },
                                    {
                                        title: 'Device Healthy signal',
                                        errorText,
                                        content: (
                                            <>
                                                <SpaceBetween size={'xl'}>
                                                    {progressPercentage < 100 && (
                                                        <>
                                                            <Header variant="h3">
                                                                Waiting for healthy signal from device
                                                            </Header>
                                                            <ProgressBar
                                                                value={progressPercentage}
                                                                additionalInfo="This can take up to 15 minutes... keep ignition on"
                                                                label="Waiting for healthy signal from device"
                                                            />
                                                        </>
                                                    )}
                                                    {progressPercentage == 100 && <Header variant="h3">Success</Header>}
                                                    <ServiceInformation
                                                        input_vin={vin}
                                                        device_information={data && data['vinAssociatedDeviceId']}
                                                        images={capturedImagesBeforeRepair}
                                                        showCamera={showCamera}
                                                        webcamRef={webcamRef}
                                                        onAddImage={handleAddPhoto}
                                                        onCapturePhoto={capturePicturesBeforeRepair}
                                                        onDeleteImage={deleteImageBeforeRepair}
                                                        uploadStatuses={uploadStatusesBeforeRepair}
                                                    />
                                                </SpaceBetween>
                                            </>
                                        ),
                                    },
                                    {
                                        title: 'Take a Picture of successful repair',
                                        errorText,
                                        content: (
                                            <>
                                                <ImageCapture
                                                    vin={vin}
                                                    device_information={data}
                                                    webcamRef={webcamRef}
                                                    captureImage={capturePicturesAfterRepair}
                                                    switchCamera={switchCamera}
                                                    facingMode={facingMode}
                                                    capturedImages={capturedImagesAfterRepair}
                                                    deleteImage={deleteImageAfterRepair}
                                                    uploadStatuses={uploadStatusesAfterRepair}
                                                />
                                            </>
                                        ),
                                    },
                                    {
                                        title: 'Success',
                                        errorText,
                                        content: <>Click Done to go to homepage</>,
                                    },
                                ]}
                            />
                        }
                    />
                </form>
            </Container>
            {isFailureModalOpen && (
                <FailureRepairModal
                    visible={isFailureModalOpen}
                    setVisible={setFailureModalOpen}
                    inputVin={vin}
                    deviceInfo={data}
                    setErrorMessage={handleRepairFailure}
                />
            )}
        </>
    );
};

export default RepairForm;
