import * as React from 'react';
import { Container } from '@amzn/awsui-components-react/polaris';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Login from './auth/login';

export default () => {
    return (
        <Container>
            <SpaceBetween direction="vertical" size="m">
                <Login />
            </SpaceBetween>
        </Container>
    );
};
