import * as React from 'react';
import { Button, FormField, Input } from '@amzn/awsui-components-react';
import { useCallback, useMemo, useState } from 'react';
import CameraModal from './cameraModal';
import decodeImage from './camera/imageDecoder';

type Props = {
    setVin: (vin: string) => void;
    vin: string;
    isVinValid: boolean;
};
const VinInput = ({ setVin, vin, isVinValid }: Props) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const [currentConfig, setCurrentConfig] = useState('vin');
    const [vinCameraLoading, setVinCameraLoading] = useState(false);

    const setVinFromImage = useCallback(
        (img: any) => {
            setVinCameraLoading(true);

            decodeImage(img, (text?: string, error?: any) => {
                if (text) {
                    setVin(vin);
                }
                setVinCameraLoading(false);
            });
        },
        [setVin],
    );

    const config: { [key: string]: any } = useMemo(() => {
        return {
            vin: {
                config_name: 'vin',
                callback: setVinFromImage,
                setText: setVin,
                formHeader: 'Vehicle VIN',
                cameraHeader: 'Take Picture of VIN Barcode',
                state: vin,
                setState: setVin,
            },
        };
    }, [vin, setVin]);

    const cameraVinButtonClick = () => {
        setCurrentConfig(config['vin'].config_name);
        setVisibleModal(true);
    };

    return (
        <div>
            <CameraModal
                setUserImageCallback={config[currentConfig].callback}
                isValidTextOutput={config[currentConfig].validateText}
                setTextOutput={config[currentConfig].setText}
                visible={visibleModal}
                setVisible={setVisibleModal}
                header={config[currentConfig].cameraHeader}
            />
            <FormField label={config['vin'].formHeader}>
                <Input
                    value={config['vin'].state}
                    onChange={({ detail }) => config['vin'].setState(detail.value.toUpperCase())}
                    invalid={!isVinValid}
                />
                <Button
                    variant="inline-icon"
                    iconName={'video-on'}
                    loading={vinCameraLoading}
                    onClick={cameraVinButtonClick}
                />
            </FormField>
        </div>
    );
};

export default VinInput;
