import * as React from 'react';
import { Container, Button } from '@amzn/awsui-components-react/polaris';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

const Home = () => {
    return (
        <Container>
            <SpaceBetween direction="vertical" size="m">
                <Button variant="primary" href={'/onboarding'}>
                    Start/Check Installation
                </Button>
                <Button variant="primary" href={'/repair'}>
                    Repair Device
                </Button>
                <Button href={'/installationdata'}>View Past Installs</Button>
            </SpaceBetween>
        </Container>
    );
};

export default Home;
