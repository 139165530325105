import * as React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { Box, SpaceBetween, Button } from '@amzn/awsui-components-react';
import ReportRepairFailure from './reportRepairFailure';

type Props = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    inputVin: string;
    deviceInfo: any;
    setErrorMessage: (error: any, vin: string) => void;
};

export default ({ visible, setVisible, inputVin, deviceInfo, setErrorMessage }: Props) => {
    const callback = (error: any, vin: string) => {
        setErrorMessage(error, vin);
        setVisible(false);
    };
    return (
        <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => setVisible(false)}>
                            Cancel
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="Report why repair cannot be done"
        >
            <ReportRepairFailure callback={callback} inputVin={inputVin} deviceInfo={deviceInfo} />
        </Modal>
    );
};
