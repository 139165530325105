import { createSlice } from '@reduxjs/toolkit';
export const onboardingPageSlice = createSlice({
    name: 'onboardingPage',
    initialState: {
        deviceSerial: null,
        vin: null,
    },
    reducers: {
        updateDeviceSerial: (state, param) => {
            state.deviceSerial = param.payload;
        },
        updateVin: (state, param) => {
            state.vin = param.payload;
        },
        clearOnboarding: (state) => {
            state.deviceSerial = null;
            state.vin = null;
            console.log(state);
        },
    },
});

export const onboardingPageReducer = onboardingPageSlice.reducer;
export const { updateDeviceSerial, updateVin, clearOnboarding } = onboardingPageSlice.actions;
