import { store } from '../app/store';
import { getApiGatwayUrl } from './deviceStatusHandler';
const apigClientFactory = require('aws-api-gateway-client').default;

export const uploadPictures = async (vin: string, deviceId: string, stepName: string, imageSrc: string) => {
    const DATA_PREFIX = 'data:';
    const BASE64_SUFFIX = ';base64,';
    if (!imageSrc) return false;

    const client = apigClientFactory.newClient({
        invokeUrl: getApiGatwayUrl(),
        retries: 3,
    });

    const hasValidPrefix = imageSrc.startsWith(DATA_PREFIX) && imageSrc.includes(BASE64_SUFFIX);
    if (!hasValidPrefix) {
        console.log('Invalid base64 String');
        return false;
    }

    const base64String = imageSrc.split(',')[1];

    const payload = {
        vin: vin,
        deviceId: deviceId,
        stepName: stepName,
        image: base64String,
    };

    try {
        const response = await client.invokeApi(
            {},
            '/upload_repair_pictures',
            'POST',
            {
                headers: {
                    Authorization: store.getState().userData.userInfo!.jwt,
                },
            },
            payload,
        );
        return true;
    } catch (error) {
        console.error('Error uploading image:', error);
        return false;
    }
};
