import { DecodeHintType } from '@zxing/library';
import { BrowserMultiFormatReader } from '@zxing/browser';
const hints = new Map();
hints.set(DecodeHintType.TRY_HARDER, true);
const codeReader = new BrowserMultiFormatReader(hints);

const decodeImage = (img: any, callback: (text?: string, error?: any) => void) => {
    // image regular
    const image = new Image();
    image.src = img;

    // image inverted
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d') as any;
    canvas.width = image.width;
    canvas.height = image.height;
    context.filter = 'invert(1)';
    context.drawImage(image, 0, 0);
    const dataUrl = canvas.toDataURL();
    const newInverted = new Image();
    newInverted.src = dataUrl;

    const promise1 = codeReader.decodeFromImageElement(newInverted);
    const promise2 = codeReader.decodeFromImageElement(image);

    Promise.all([promise1.catch((e) => 'failure'), promise2.catch((e) => 'failure')]).then((results) => {
        console.log(results);
        if (results[0] !== 'failure') {
            callback((results[0] as any).getText());
        } else if (results[1] !== 'failure') {
            callback((results[1] as any).getText());
        } else {
            callback(undefined, 'failure');
        }
    });
};
export default decodeImage;
