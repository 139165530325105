import * as React from 'react';
import { Button, SpaceBetween, Header, FormField, Input, Form, Link } from '@amzn/awsui-components-react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoLogin } from '../../services/authenticationService';
import { AppConfigContext } from '../../config/appConfigContext';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserInfo } from '../../slice/userInfoSlice';
import { EventName, EventType, logMetricEvent } from '../../services/eventService';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formSubmit, setFormSubmit] = useState(false);
    const [formError, setFormError] = useState(false);
    const { config }: { config: any } = useContext(AppConfigContext);
    const cognito_config = config.cognito_config;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmitForm = async (formData: any) => {
        formData.preventDefault();
        setFormSubmit(true);
        //make sure all fields are present
        if (email && password) {
            //call cognito here
            CognitoLogin(
                {
                    userPoolId: cognito_config.user_pool_id,
                    clientId: cognito_config.client_id,
                    email: email,
                    password: password,
                },
                function (success: boolean, value: any, newPassword: boolean, loginStartTime: number) {
                    if (success) {
                        setFormError(false);
                        dispatch(updateUserInfo(value));
                    } else if (!newPassword) {
                        setFormError(true);
                    }

                    if (!newPassword) {
                        logMetricEvent({
                            eventName: EventName.LOGIN_ATTEMPT,
                            eventType: success ? EventType.SUCCESS : EventType.FAILURE,
                            duration_ms: Date.now() - loginStartTime,
                            cookies: value,
                        });
                    }

                    if (success || newPassword) {
                        const navigationPath = newPassword ? '/forgotpassword' : '/';
                        navigate(navigationPath);
                    }
                },
            );
        }
    };

    return (
        <div>
            <form onSubmit={onSubmitForm}>
                <Form
                    errorText={formError && 'Log in failed. Please try again.'}
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary">Log in</Button>
                        </SpaceBetween>
                    }
                >
                    <Header variant="h2">Enter Login Details</Header>
                    <SpaceBetween direction="vertical" size="xs">
                        <FormField label="Email" errorText={formSubmit && !email && 'Please enter email'}>
                            <Input
                                type={'email'}
                                value={email}
                                onChange={({ detail }) => setEmail(detail.value.toLowerCase())}
                            />
                        </FormField>
                        <FormField label="Password" errorText={formSubmit && !password && 'Please enter password'}>
                            <Input
                                type="password"
                                value={password}
                                onChange={({ detail }) => setPassword(detail.value)}
                            />
                        </FormField>
                        <div style={{ textAlign: 'left' }}>
                            <Link variant="primary" href="forgotpassword">
                                {' '}
                                Forgot password?{' '}
                            </Link>
                        </div>
                    </SpaceBetween>
                </Form>
            </form>
        </div>
    );
};

export default Login;
