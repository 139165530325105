import { createSlice } from '@reduxjs/toolkit';
export const dynamicConfigSlice = createSlice({
    name: 'dynamicConfig',
    initialState: {
        feedbackEmail: null,
    },
    reducers: {
        updateFeedbackEmail: (state, param) => {
            state.feedbackEmail = param.payload;
        },
        clearDynamicConfig: (state) => {
            state.feedbackEmail = null;
        },
    },
});

export const dynamicConfigReducer = dynamicConfigSlice.reducer;
export const { updateFeedbackEmail, clearDynamicConfig } = dynamicConfigSlice.actions;
