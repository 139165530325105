import React, { useCallback, useEffect, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { Button, Container } from '@amzn/awsui-components-react';

type Props = {
    isValidTextOutput: (text: string) => boolean;
    setTextOutput: (text: string) => void;
};

const config = {
    fps: 10,
    qrbox: 250,
    aspectRatio: 1,
    disableFlip: false,
};

const divid = 'qrcode-reader';

type facingModeType = 'user' | 'environment';

const AutoCameraV2 = ({ isValidTextOutput, setTextOutput }: Props) => {
    const [facingMode, setFacingMode] = useState<facingModeType>('environment');
    const switchCamera = useCallback(
        () => setFacingMode((prevState) => (prevState === 'user' ? 'environment' : 'user')),
        [setFacingMode],
    );

    const qrCodeSuccessCallback = useCallback((decodedText: string, decodedResult: any) => {
        if (isValidTextOutput(decodedText)) {
            console.log('found code but not valid', decodedText);
        }
        setTextOutput(decodedText);
    }, []);
    const errorCallback = useCallback((error: any) => {
        if (error.inclues('NotFoundException')) return;
        console.log('error', error);
    }, []);
    useEffect(() => {
        if (!document.getElementById(divid)) {
            return;
        }
        const html5QrCode = new Html5Qrcode(/* element id */ divid, {
            verbose: false,
            useBarCodeDetectorIfSupported: true,
        });

        const start = () => {
            html5QrCode.start({ facingMode }, config, qrCodeSuccessCallback, errorCallback);
        };

        const stopAndClear = () => {
            html5QrCode.isScanning &&
                html5QrCode
                    .stop()
                    .then((ignore) => {
                        // QR Code scanning is stopped.
                        html5QrCode.clear();
                    })
                    .catch((err) => {
                        // Stop failed, handle it.
                    });
        };

        start();

        return stopAndClear;
    });

    return (
        <>
            <div id={divid} />
            <Button onClick={switchCamera}>Switch camera</Button>
        </>
    );
};

export default AutoCameraV2;
