import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import UserPool from '../cognito/userPool';
import jwt_decode from 'jwt-decode';

function createCognitoUser(userPoolId: any, clientId: any, username: any) {
    const userData = {
        Username: username,
        Pool: UserPool(userPoolId, clientId),
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    return cognitoUser;
}

export function CognitoSignUp(creds: any, cb: any) {
    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute({ Name: 'email', Value: creds.email });
    const attributeFirstName = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'given_name',
        Value: creds.firstName,
    });
    const attributeLastName = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'family_name',
        Value: creds.lastName,
    });
    const attributeCompany = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: 'custom:company',
        Value: creds.company,
    });
    UserPool(creds.userPoolId, creds.clientId).signUp(
        creds.email,
        creds.password,
        [attributeEmail, attributeFirstName, attributeLastName, attributeCompany],
        [],
        cb,
    );
}

export function CognitoConfirmEmail(creds: any, cb: any) {
    const cognitoUser = createCognitoUser(creds.userPoolId, creds.clientId, creds.email);
    cognitoUser.confirmRegistration(creds.code, true, cb);
}

export function CognitoLogin(creds: any, cb: any) {
    const authenticationData = {
        Username: creds.email,
        Password: creds.password,
    };
    const loginStartTime = Date.now();
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    var obj: { [k: string]: any } = {};
    const cognitoUser = createCognitoUser(creds.userPoolId, creds.clientId, creds.email);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result: any) {
            const accessToken = result.getIdToken().getJwtToken();
            const groupToken = result.getAccessToken().getJwtToken();
            const groupDecode: any = jwt_decode(groupToken);
            const group = groupDecode['cognito:groups'];
            obj['group'] = group;
            obj['jwt'] = accessToken;
            const decoded: any = jwt_decode(obj['jwt']);
            obj['username'] = decoded['cognito:username'];
            obj['expire'] = decoded.exp;
            cognitoUser.getUserAttributes(function (err, result) {
                if (err || result == undefined) {
                    cb(false, err, false, loginStartTime);
                    return err;
                }
                for (let i = 0; i < result.length; i++) {
                    obj[result[i].getName()] = result[i].getValue();
                }
                cb(true, obj, false, loginStartTime);
            });
        },
        onFailure: function (err: any) {
            cb(false, err, false, loginStartTime);
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
            cb(false, {}, true, loginStartTime);
        },
    });
}

//forgot password
export function CognitoForgotPassword(creds: any, cb: any) {
    const cognitoUser = createCognitoUser(creds.userPoolId, creds.clientId, creds.email);
    cognitoUser.forgotPassword({
        onSuccess: function (data) {
            cb(true, data);
        },
        onFailure: function (err) {
            cb(false, err);
        },
    });
}

export function CognitoNewPassword(creds: any, cb: any) {
    const cognitoUser = createCognitoUser(creds.userPoolId, creds.clientId, creds.email);
    cognitoUser.confirmPassword(creds.code, creds.newPassword, {
        onSuccess: function (data) {
            cb(true, data);
        },
        onFailure: function (err) {
            cb(false, err);
        },
    });
}

//change user password (must authenticate user first)
export function CognitoChangePassword(creds: any, cb: any) {
    const authenticationData = {
        Username: creds.email,
        Password: creds.oldPassword,
    };
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    const cognitoUser = createCognitoUser(creds.userPoolId, creds.clientId, creds.email);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result: any) {
            cognitoUser.changePassword(creds.oldPassword, creds.newPassword, function (err, result) {
                if (err) {
                    return cb(false, err);
                }
                cb(true, result);
            });
        },
        onFailure: function (err: any) {
            cb(false, err);
        },
    });
}

export function CognitoRefreshToken(creds: any, cb: any, expire: any) {
    var poolData = {
        UserPoolId: creds.userPoolId,
        ClientId: creds.clientId,
    };
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
        cognitoUser.getSession(function (err: any, session: any) {
            if (err) {
                console.log(err);
                cb(false, false, null);
            } else {
                if (expire < Date.now() / 1000) {
                    const refresh_token = session.getRefreshToken();
                    if (cognitoUser) {
                        cognitoUser.refreshSession(refresh_token, (err, session) => {
                            if (err) {
                                cb(false, false, null);
                                console.log(err);
                            } else {
                                var obj: { [k: string]: any } = {};
                                const decoded: any = jwt_decode(session.getIdToken().getJwtToken());
                                const accessToken = session.getIdToken().getJwtToken();
                                const groupToken = session.getAccessToken().getJwtToken();
                                const groupDecode: any = jwt_decode(groupToken);
                                const group = groupDecode['cognito:groups'];
                                obj['username'] = decoded['cognito:username'];
                                obj['group'] = group;
                                obj['jwt'] = accessToken;
                                obj['expire'] = decoded.exp;
                                if (cognitoUser) {
                                    cognitoUser.getUserAttributes(function (err, result) {
                                        if (err || result == undefined) {
                                            cb(false, false, null);
                                            console.log(err);
                                            return err;
                                        }
                                        for (let i = 0; i < result.length; i++) {
                                            obj[result[i].getName()] = result[i].getValue();
                                        }
                                        cb(true, true, obj);
                                    });
                                } else {
                                    console.log('invalid session');
                                    cb(false, false, null);
                                }
                            }
                        });
                    }
                } else {
                    cb(true, false, null);
                }
            }
        });
    } else {
        console.log('invalid session');
        cb(false, false, null);
    }
}
