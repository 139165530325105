import { createSlice } from '@reduxjs/toolkit';
export const checkPageSlice = createSlice({
    name: 'checkPage',
    initialState: {
        deviceSerial: null,
        vin: null,
    },
    reducers: {
        updateDeviceSerial: (state, param) => {
            state.deviceSerial = param.payload;
        },
        updateVin: (state, param) => {
            state.vin = param.payload;
        },
        clearCheck: (state) => {
            state.deviceSerial = null;
            state.vin = null;
        },
    },
});

export const checkPageReducer = checkPageSlice.reducer;
export const { updateDeviceSerial, updateVin } = checkPageSlice.actions;
