import React, { useContext, useEffect, useState } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Login from './pages/auth/login';
import OnboardingForm from './pages/device/onboardingform';
import RepairForm from './pages/device/repairForm';
import UnauthenticatedHome from './pages/unauthenticatedHome';
import Check from './pages/device/Check';
import Home from './pages/home';
import ForgotPassword from './pages/auth/forgotPassword';
import ChangePassword from './pages/auth/changePassword';
import VinInformationPage from './pages/vinInformationPage';
import StartPage from './pages/startPage';
import { AppConfigContext } from './config/appConfigContext';
import { CognitoRefreshToken } from './services/authenticationService';
import { EventType, EventName, logMetricEvent } from './services/eventService';
import InstallationData from './pages/data/installationData';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserInfo, clearUserInfo } from './slice/userInfoSlice';
import dynamicConfig from './services/dynamicConfig';
import { updateFeedbackEmail } from './slice/dynamicConfigSlice';
import { updateMultiselectConfig } from './slice/multiselectOptionsSlice';

if (process.env.NODE_ENV == 'production') {
    console.log = function () {};
}
function createRouterUnauthenticated() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <UnauthenticatedHome />,
        },
        {
            path: '/login',
            element: <Login />,
        },
        {
            path: '/forgotpassword',
            element: <ForgotPassword />,
        },
        {
            path: '/changepassword',
            element: <ChangePassword />,
        },
        {
            path: '*',
            element: <Navigate to={'/'} />,
        },
    ]);
    return router;
}

function createRouter() {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <StartPage />,
        },
        {
            path: '/vinInformation',
            element: <VinInformationPage />,
        },
        {
            path: '/home',
            element: <Home />,
        },
        {
            path: '/onboarding',
            element: <OnboardingForm />,
        },
        {
            path: '/repair',
            element: <RepairForm />,
        },
        {
            path: '/installationdata',
            element: <InstallationData />,
        },
        {
            path: '/check',
            element: <Check />,
        },
        {
            path: '/changepassword',
            element: <ChangePassword />,
        },
        {
            path: '*',
            element: <Navigate to={'/'} />,
        },
    ]);
    return router;
}

function App() {
    const { config }: { config: any } = useContext(AppConfigContext);
    const [loading, setLoading] = useState(true);
    const cognito_config = config.cognito_config;
    const info = useSelector((state: any) => state.userData.userInfo);
    const feedbackEmail = useSelector((state: any) => state.dynamicConfig.feedbackEmail);

    const dispatch = useDispatch();
    useEffect(() => {
        if (cognito_config) {
            //will set to false on context change
            setLoading(false);
        }
    });

    useEffect(() => {
        fetch('options.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then(
                (response) => {
                    return response.json();
                },
                (err) => console.log(err),
            )
            .then((response) => {
                dispatch(updateMultiselectConfig(response));
            });
    }, [dispatch]);

    useEffect(() => {
        if (info && !loading) {
            dynamicConfig((success: boolean, data: any) => {
                if (success) {
                    dispatch(updateFeedbackEmail(data.feedbackEmail));
                } else {
                    console.log('error', data);
                }
            });
        }
    }, [loading, info]);

    useEffect(() => {
        if (info && !loading) {
            CognitoRefreshToken(
                {
                    userPoolId: cognito_config.user_pool_id,
                    clientId: cognito_config.client_id,
                    email: info.email,
                },
                function (success: boolean, newToken: boolean, value: any) {
                    if (success) {
                        if (newToken) {
                            dispatch(updateUserInfo(value));
                            logMetricEvent({
                                eventName: EventName.COGNITO_TOKEN_REFRESH,
                                eventType: success ? EventType.SUCCESS : EventType.FAILURE,
                            });
                        }
                    } else {
                        dispatch(clearUserInfo());
                    }
                },
                info.expire,
            );
        }
    }, [loading]);

    if (loading) {
        return <div> Loading... </div>;
    } else {
        //check if user is logged in
        if (info) {
            return <RouterProvider router={createRouter()} />;
        } else {
            return <RouterProvider router={createRouterUnauthenticated()} />;
        }
    }
}

export default App;
