import * as React from 'react';
import { Button, SpaceBetween, Header, Container, FormField, Input, Form, Link } from '@amzn/awsui-components-react';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoChangePassword } from '../../services/authenticationService';
import { AppConfigContext } from '../../config/appConfigContext';
import PasswordChecklist from 'react-password-checklist';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import { useSelector, useDispatch } from 'react-redux';

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [verifyNewPassword, setVerifyNewPassword] = useState('');
    const [formSubmit, setFormSubmit] = useState(false);
    const [formError, setFormError] = useState(false);
    const navigate = useNavigate();
    const email = useSelector((state: any) => state.userData.userInfo.email || null);
    const { config }: { config: any } = useContext(AppConfigContext);
    const cognito_config = config.cognito_config;

    const onSubmitForm = async (formData: any) => {
        formData.preventDefault();
        setFormSubmit(true);
        //make sure all fields are present
        if (newPassword && oldPassword && verifyNewPassword == newPassword) {
            CognitoChangePassword(
                {
                    userPoolId: cognito_config.user_pool_id,
                    clientId: cognito_config.client_id,
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                    email: email,
                },
                function (success: boolean, value: any) {
                    if (success) {
                        setFormError(false);
                        navigate('/');
                    } else {
                        setFormError(true);
                    }
                },
            );
        }
    };

    return (
        <div>
            <form onSubmit={onSubmitForm}>
                <Form
                    errorText={formError && 'Error changing password. Please try again.'}
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary">Log in</Button>
                        </SpaceBetween>
                    }
                >
                    <Header variant="h2">Enter Details. You will be redirected to login after password change.</Header>
                    <SpaceBetween direction="vertical" size="xs">
                        <FormField
                            label="Old Password"
                            errorText={formSubmit && !oldPassword && 'Please enter old password'}
                        >
                            <Input
                                type="password"
                                value={oldPassword}
                                onChange={({ detail }) => setOldPassword(detail.value)}
                            />
                        </FormField>
                        <FormField
                            label="New Password"
                            errorText={formSubmit && !newPassword && 'Please enter new password'}
                        >
                            <Input
                                type="password"
                                value={newPassword}
                                onChange={({ detail }) => setNewPassword(detail.value)}
                            />
                        </FormField>
                        <FormField
                            label="Re-enter new Password"
                            errorText={
                                formSubmit && verifyNewPassword != newPassword && 'Please make sure passwords match'
                            }
                        >
                            <Input
                                type="password"
                                value={verifyNewPassword}
                                onChange={({ detail }) => setVerifyNewPassword(detail.value)}
                            />
                        </FormField>
                        <PasswordChecklist
                            rules={['minLength', 'specialChar', 'number', 'capital', 'match', 'lowercase']}
                            minLength={12}
                            value={newPassword}
                            valueAgain={verifyNewPassword}
                            onChange={(isValid) => {}}
                            iconComponents={{
                                ValidIcon: (
                                    <div>
                                        {' '}
                                        &nbsp;
                                        <Icon name="check" size="medium" variant="success" />
                                        &nbsp;{' '}
                                    </div>
                                ),
                                InvalidIcon: (
                                    <div>
                                        &nbsp; <Icon name="status-negative" size="medium" variant="error" /> &nbsp;
                                    </div>
                                ),
                            }}
                        />
                    </SpaceBetween>
                </Form>
            </form>
            <div>&nbsp;</div>
            <div style={{ textAlign: 'right' }}>
                New user? &nbsp;
                <Link variant="primary" href="/registration">
                    Register here{' '}
                </Link>
            </div>
        </div>
    );
};

export default ChangePassword;
