import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import { Container } from '@amzn/awsui-components-react';
import DeviceInformation from '../../components/deviceInformation';
import deviceStatusHandler from '../../services/deviceStatusHandler';
import CheckDeviceInfo, { CheckDeviceMode } from '../../components/CheckDeviceInfo';
import { EventName, EventType, logMetricEvent } from '../../services/eventService';
import { useDispatch, useSelector } from 'react-redux';
import { updateDeviceSerial, updateVin } from '../../slice/checkPageSlice';

const Check = () => {
    const [startTime, setStartTime] = useState(0);
    const deviceInformation = useRef({} as any);
    const inputVin = useRef('');
    const vin = useSelector((state: any) => state.checkPage.vin);
    const [formVin, setFormVin] = useState(useSelector((state: any) => state.checkPage.vin || ''));
    const [formDeviceSerial, setFormDeviceSerial] = useState(
        useSelector((state: any) => state.checkPage.deviceSerial || ''),
    );
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const callBack = useCallback(
        (success: boolean, data: any) => {
            if (success) {
                deviceInformation.current = data;
            }
            logMetricEvent({
                deviceId: deviceInformation.current,
                vin: inputVin.current,
                eventName: EventName.CHECK_STATUS,
                eventType: success ? EventType.SUCCESS : EventType.FAILURE,
                miscEventData: {
                    data,
                },
                duration_ms: Date.now() - startTime,
            });
            setLoading(false);
        },
        [setLoading, startTime],
    );
    const onClear = useCallback(() => {
        dispatch(updateVin(''));
        dispatch(updateDeviceSerial(''));
        deviceInformation.current = {};
        setStartTime(0);
    }, [setStartTime]);

    const onSubmitForm = useCallback(
        ({ deviceSerialNumber, vin }: any) => {
            setLoading(true);
            logMetricEvent({
                deviceId: deviceSerialNumber,
                vin: vin,
                eventName: EventName.CHECK_STATUS,
                eventType: EventType.START,
                duration_ms: 0,
            });
            inputVin.current = vin;
            dispatch(updateVin(vin));
            dispatch(updateDeviceSerial(deviceSerialNumber));
            deviceStatusHandler(deviceSerialNumber, vin, true, callBack);
        },
        [setLoading, setStartTime],
    );

    const onStop = () => setLoading(false);

    return (
        <div>
            <Container>
                <CheckDeviceInfo
                    checkDeviceMode={CheckDeviceMode.DEVICE_AND_VIN_CHECK}
                    onSubmit={onSubmitForm}
                    onClear={onClear}
                    onStop={onStop}
                    submitButtonString={'Check Status'}
                    submitButtonLoading={loading}
                    initialVin={formVin}
                    initialDeviceSerialNumber={formDeviceSerial}
                />
                {deviceInformation.current['deviceId'] ? (
                    <DeviceInformation
                        input_vin={inputVin.current}
                        input_device={deviceInformation.current['deviceId']}
                        device_information={deviceInformation.current}
                    />
                ) : (
                    <div />
                )}
            </Container>
        </div>
    );
};

export default Check;
