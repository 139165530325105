import { Form, SpaceBetween, Button, Header, Select } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useRef, useState } from 'react';

const REPLACE_FLEET_EDGE = 'Replace Fleet Edge Box.';
const RESTART_REPLACE_FLEET_EDGE = 'Restart device. Replace Fleet Edge box.';
const GENERAL = 'general';
const CAMEREA = 'Camera';
const GNSS = 'GNSS';
const LTE = 'LTE';
const SECURITY = 'Security';
const WIFI = 'Wi-Fi';
const VPU = 'VPU';
const IGNITION = 'Ignition';
const SECURE_BOOT = 'Secure Boot';
const PROVISIONING = 'Provisioning';
const VEHICLE = 'Vehicle';
const ACTIVATION = 'Activation';
const UPDATING = 'Updating';
const NO_ISSUE = 'NO ISSUE.';

const REPLACE_FLEET_EDGE_CODE_GROUPS = {
    'SEC Error': { GENERAL: ['0C', '0D', '0E', '0F'] },
    'PEI Error': {
        GENERAL: ['50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '5A', '5B', '5C', '5D', '5E', '5F'],
    },
    'DXE Error': { GENERAL: ['D0', 'D1', 'D2', 'D3', 'D4', 'D5', 'D6', 'D7', 'D8', 'D9', 'DA', 'DB', 'DC', 'DD'] },
    'Modem device not accessible.': { LTE: ['H4'] },
    'SIM not found.': { LTE: ['H5'] },
    'TPM device not properly configured.': { LTE: ['H9'] },
    'Wi-Fi component not detected.': { WIFI: ['HA'] },
    'Wi-Fi component not functional.': { WIFI: ['HB'] },
    'Vision Processor not detected.': { VPU: ['HC'] },
    'Secure Boot not Enabled.': { SECURE_BOOT: ['HE'] },
    'Software provisioning process failures. Contact Fleet Edge engineering for further details.': {
        Provisioning: ['U1'],
    },
    'TPM error.': { Provisioning: ['U2'] },
    'Invalid device, device ID does not exist in inventory table.': { Provisioning: ['U5'] },
    'Device provisioning failed.': { Provisioning: ['U8'] },
};

const RESTART_REPLACE_FLEET_EDGE_CODE_GROUPS = {
    'Network error, can’t reach cloud. ': { Provisioning: ['U3'] },
    'Timeout connecting to cloud.': { Provisioning: ['U4'] },
    'Software activation and cloud registration failures. Contact Fleet Edge engineering for further details.': {
        ACTIVE_TEXTURE: ['UC', 'UE'],
    },
};

export const POSSIBLE_ERROR_CODES: { [key: string]: any } = {
    'NO CODE': {
        description: 'No power or electronics failure.',
        system: GENERAL,
        action: 'Go to section NO CODE.',
    },
    A0: {
        description: 'Booting.',
        system: GENERAL,
        action: 'Wait a few more minutes, otherwise restart system.',
    },
    L1: {
        description: 'Could not detect camera System.',
        system: CAMEREA,
        action: 'Go to section L1. ',
    },
    L2: {
        description: 'Front view camera not found.',
        system: CAMEREA,
        action: 'Go to section L2. ',
    },
    L3: {
        description: 'Front view camera not found.',
        system: CAMEREA,
        action: NO_ISSUE,
        success: true,
    },
    L4: {
        description: 'Cargo camera not found.',
        system: CAMEREA,
        action: NO_ISSUE,
        success: true,
    },
    L5: {
        description: 'Front view camera not functional.',
        system: CAMEREA,
        action: 'Go to section L5.',
    },
    L6: {
        description: 'Driver facing camera not functional.',
        system: CAMEREA,
        action: NO_ISSUE,
        success: true,
    },
    L7: {
        description: 'Cargo facing camera not functional.',
        system: CAMEREA,
        action: NO_ISSUE,
        success: true,
    },
    H0: {
        description: 'GNSS Antenna not working.',
        system: GNSS,
        action: 'Go to section H0.',
    },
    H1: {
        description: 'GNSS device not accessible.',
        system: GNSS,
        action: 'Go to section H1/H2/H3.',
    },
    H2: {
        description: 'GNSS hardware not present.',
        system: GNSS,
        action: 'Go to section H1/H2/H3.',
    },
    H3: {
        description: 'GNSS hardware read failure.',
        system: GNSS,
        action: 'Go to section H1/H2/H3.',
    },
    H7: {
        description: 'No internet access.',
        system: LTE,
        action: 'Go to section H7/H8.',
    },
    H8: {
        description: 'No SSH access (internal use).',
        system: LTE,
        action: 'Go to section H7/H8.',
    },
    HD: {
        description: 'Ignition signal not detected.',
        system: IGNITION,
        action: 'Go to section HD/IGNITION..',
    },
    U9: {
        description: 'VIN acquisition failure.',
        system: VEHICLE,
        action: 'Go to section U9..',
    },
    UF: {
        description: 'Updating Firmware.',
        system: UPDATING,
        action: 'Go to section UF.',
    },
};

for (const [description, value] of Object.entries(REPLACE_FLEET_EDGE_CODE_GROUPS)) {
    for (const [system, label] of Object.entries(value)) {
        for (const l of label) {
            Object.defineProperty(POSSIBLE_ERROR_CODES, l, {
                value: {
                    description: description,
                    system: system,
                    action: REPLACE_FLEET_EDGE,
                },
                enumerable: true,
            });
        }
    }
}

for (const [description, value] of Object.entries(RESTART_REPLACE_FLEET_EDGE_CODE_GROUPS)) {
    for (const [system, label] of Object.entries(value)) {
        for (const l of label) {
            Object.defineProperty(POSSIBLE_ERROR_CODES, l, {
                value: {
                    description: description,
                    system: system,
                    action: RESTART_REPLACE_FLEET_EDGE,
                },
                enumerable: true,
            });
        }
    }
}

const POSSIBLE_ERROR_CODES_OPTIONS: any[] = [];
for (const [key, value] of Object.entries(POSSIBLE_ERROR_CODES)) {
    POSSIBLE_ERROR_CODES_OPTIONS.push({
        label: key,
        value: key,
    });
}

type Props = {
    inputVin: string;
    deviceInfo: any;
    callback: (error: any) => void;
};

const ReportFailure = ({ inputVin, deviceInfo, callback }: Props) => {
    const [selectedErrorCode, setSelectedErrorCode] = useState(null);

    const onSubmit = (e: any) => {
        e.preventDefault();
        if (selectedErrorCode !== null) {
            const error_code_data = POSSIBLE_ERROR_CODES[selectedErrorCode['value']] as {};
            callback({
                ...error_code_data,
                error_code: selectedErrorCode['value'],
            });
        }
    };
    return (
        <div>
            <form onSubmit={onSubmit}>
                <Form
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary">Report Failure</Button>
                        </SpaceBetween>
                    }
                    header={<Header variant="h1">Report Failure</Header>}
                >
                    <Select
                        selectedOption={selectedErrorCode}
                        onChange={({ detail }) => setSelectedErrorCode(detail.selectedOption as any)}
                        options={POSSIBLE_ERROR_CODES_OPTIONS}
                        filteringType="auto"
                        placeholder="Choose status code on LED display"
                        selectedAriaLabel="Selected"
                    />
                </Form>
            </form>
        </div>
    );
};

export default ReportFailure;
