import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearData, expireInstalls } from '../../slice/installationDataSlice';
import { useEffect } from 'react';
import { Badge, Cards, StatusIndicator } from '@amzn/awsui-components-react';

function getDate(date: any) {
    var newDate = new Date(date * 1000);
    return newDate.toLocaleString();
}
function getBadgeColor(bool: boolean) {
    if (bool) {
        return 'green';
    }
    return 'red';
}
function getVinText(bool: boolean) {
    if (bool) {
        return 'Vin check success';
    }
    return 'Vin check failure';
}
function getInstallText(bool: boolean) {
    if (bool) {
        return 'Installation successful';
    }
    return 'Installation failed';
}

const cardDefinition = {
    header: (obj: any) => obj.deviceSerial,
    sections: [
        {
            id: 'vin',
            content: (obj: any) => 'Vin: ' + obj.vin,
        },
        {
            id: 'vinCheck',
            content: (obj: any) => <Badge color={getBadgeColor(obj.vinCheck)}> {getVinText(obj.vinCheck)} </Badge>,
        },
        {
            id: 'installationSuccessful',
            content: (obj: any) => (
                <Badge color={getBadgeColor(obj.installationSuccessful)}>
                    {getInstallText(obj.installationSuccessful)}{' '}
                </Badge>
            ),
        },
        {
            id: 'time',
            header: 'Last checked at: ',
            content: (obj: any) => getDate(obj.time),
        },
    ],
};

const cardsPerRow = [
    {
        minWidth: 500,
        cards: 2,
    },
];

const InstallationData = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        //expire old data
        dispatch(expireInstalls());
    }, []);
    const installationData = useSelector((state: any) => state.installationData.installs);
    console.log(installationData);
    return (
        <div>
            <h1 style={{ textAlign: 'center' }}> Installations </h1>
            <Cards items={installationData} cardDefinition={cardDefinition} cardsPerRow={cardsPerRow} />
        </div>
    );
};

export default InstallationData;
