import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Alert } from '@amzn/awsui-components-react/polaris';

const StartPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const showErrorAlert = queryParams.get('showErrorAlert');
    const errorAlert = queryParams.get('errorAlert');
    return (
        <div>
            <h1>Welcome</h1>
            <Button variant="primary" href={`/vinInformation`}>
                Start Installation or Repair
            </Button>
            {showErrorAlert && (
                <div style={{ marginTop: '20px' }}>
                    <Alert type="error" dismissible={false}>
                        {errorAlert}
                    </Alert>
                </div>
            )}
        </div>
    );
};

export default StartPage;
