import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Button } from '@amzn/awsui-components-react';
import { AppConfigContext } from '../config/appConfigContext';
import { useSearchParams } from 'react-router-dom';
import { readAndCompressImage } from 'browser-image-resizer';

type Props = {
    setUserImageCallback: (img: any) => void;
};

type facingMode = 'user' | 'environment';
const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
        return 'Android';
    } else if (/iPad|iPhone|iPod/.test(ua)) {
        return 'iOS';
    }
    return 'Other';
};
const os = getMobileOS();
const isMobile = os === 'Android' || os === 'iOS';

const WebcamImage = ({ setUserImageCallback }: Props) => {
    const [facingMode, setFacingMode] = useState('user' as facingMode);
    const [hasFlashSupport, setFlashSupport] = useState(false);
    const [flashOn, setFlashOn] = useState(false);
    const [img, setImg] = useState(null);
    const webcamRef = useRef(null);
    const { config }: { config: any } = useContext(AppConfigContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const overrideCamera = searchParams.get('use_native_camera');
    const useNativeCameraForMobile =
        overrideCamera !== null ? overrideCamera === 'true' : config.installation_config.use_native_camera_for_mobile;

    const capture = useCallback(() => {
        if (!webcamRef.current) return;
        const imageSrc = (webcamRef.current as any).getScreenshot();
        console.log('screenshot');
        console.log(imageSrc);
        setImg(imageSrc);
    }, [webcamRef, setImg]);

    const switchCamera = useCallback(
        () => setFacingMode((prevState) => (prevState == 'user' ? 'environment' : 'user')),
        [setFacingMode],
    );

    const retake = useCallback(() => setImg(null), [setImg]);
    const usePhoto = () => {
        setUserImageCallback(img);
        setImg(null);
    };

    const toggleFlash = useCallback(() => {
        if (!webcamRef.current) return;
        const mediaStream: MediaStream = (webcamRef.current as any).stream;
        if (!mediaStream || mediaStream.getVideoTracks().length <= 0) return;
        const flashState = !flashOn;
        mediaStream.getVideoTracks()[0].applyConstraints({
            advanced: [{ torch: flashState } as any],
        });
        setFlashOn(flashState);
    }, [webcamRef, setFlashOn, flashOn]);

    const updateFlash = () => {
        if (!webcamRef.current) {
            setFlashSupport(false);
            return;
        }
        const mediaStream: MediaStream = (webcamRef.current as any).stream;
        if (!mediaStream || mediaStream.getVideoTracks().length <= 0) {
            setFlashSupport(false);
            return;
        }
        const torch = (mediaStream.getVideoTracks()[0].getCapabilities() as any).torch;
        setFlashSupport(torch);
    };

    const setImageFromCapture = useCallback(
        (event: any) => {
            const config = {
                quality: 0.5,
                maxWidth: 1000,
                maxHeight: 1000,
                debug: false,
            }; // make our image < 100kb

            // Note: A single file comes from event.target.files on <input>
            readAndCompressImage(event.target.files[0], config).then((resizedImage: any) => {
                console.log(resizedImage);
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    console.log('result');
                    console.log(e.target.result);
                    setImg(e.target.result);
                };
                reader.readAsDataURL(resizedImage);
            });
        },
        [setImg],
    );

    return (
        <div className="Container">
            {img === null ? (
                <>
                    {useNativeCameraForMobile && isMobile ? (
                        <>
                            <Button variant="link">
                                <label htmlFor="imgInput">Take Photo</label>
                            </Button>
                            <input
                                type="file"
                                accept="image/*"
                                capture="environment"
                                onChange={setImageFromCapture}
                                hidden={true}
                                id="imgInput"
                            />
                        </>
                    ) : (
                        <>
                            <Webcam
                                audio={false}
                                mirrored={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={'100%'}
                                onUserMedia={updateFlash}
                                videoConstraints={{ facingMode }}
                            />
                            <Button onClick={capture}>Capture photo</Button>
                            <Button onClick={switchCamera}>Switch camera</Button>
                            {hasFlashSupport ? <Button onClick={toggleFlash}>Toggle flash</Button> : <div />}
                        </>
                    )}
                </>
            ) : (
                <>
                    <img src={img} alt="screenshot" width={'100%'} />
                    <Button onClick={retake}>Retake</Button>
                    <Button onClick={usePhoto}>Use photo</Button>
                </>
            )}
        </div>
    );
};

export default WebcamImage;
