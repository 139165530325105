import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { checkPageReducer } from '../slice/checkPageSlice';
import { onboardingPageReducer } from '../slice/onboardingPageSlice';
import { installationDataReducer } from '../slice/installationDataSlice';
import { userInfoReducer } from '../slice/userInfoSlice';
import { dynamicConfigReducer } from '../slice/dynamicConfigSlice';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';
import { multiselectOptionsReducer } from '../slice/multiselectOptionsSlice';

const rootReducer = combineReducers({
    checkPage: checkPageReducer,
    onboardingPage: onboardingPageReducer,
    installationData: installationDataReducer,
    userData: userInfoReducer,
    dynamicConfig: dynamicConfigReducer,
    multiselectOptionsConfig: multiselectOptionsReducer,
});

//set expire to 12 hours
const persistConfig = {
    key: 'root',
    storage,
    transforms: [
        expireReducer('checkPage', {
            expireSeconds: 720 * 60,
            expiredState: { deviceSerial: null, vin: null },
            autoExpire: true,
        }),
        expireReducer('onboardingPage', {
            expireSeconds: 720 * 60,
            expiredState: { deviceSerial: null, vin: null },
            autoExpire: true,
        }),
        expireReducer('installationData', {
            expireSeconds: null,
        }),
        expireReducer('userData', {
            expireSeconds: null,
        }),
        expireReducer('dynamicConfig', {
            expireSeconds: null,
        }),
    ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

export const persistor = persistStore(store, null, () => console.log('LOADED'));
