import * as React from 'react';
import { StatusIndicator } from '@amzn/awsui-components-react';
import { StatusIndicatorProps } from '@amzn/awsui-components-react/polaris/status-indicator/internal';
import { Header } from '@amzn/awsui-components-react';

type Props = {
    defect_information: any;
    hide_header: boolean;
    device_id: string;
};

const STATUS_INDICATOR_ERROR: StatusIndicatorProps.Type = 'error';

export default ({ defect_information, hide_header, device_id }: Props) => {
    let listItems;
    if (defect_information) {
        listItems = defect_information.map((d: any) => (
            <StatusIndicator type={STATUS_INDICATOR_ERROR} key={d.statusReason}>
                {d.statusCode} , {d.defectReason} , {d.statusCodeNotes}{' '}
            </StatusIndicator>
        ));
    }
    return (
        <div>
            {hide_header ? (
                <ul> {listItems} </ul>
            ) : (
                <div>
                    <Header variant="h3">
                        Device Information for&nbsp;
                        {device_id}
                    </Header>
                    <ul style={{ listStyleType: 'none' }}>{listItems}</ul>
                </div>
            )}
        </div>
    );
};
