import { createSlice } from '@reduxjs/toolkit';
export const multiselectOptionsSlice = createSlice({
    name: 'multiselectOptionsConfig',
    initialState: {
        repairActionOptions: null,
        deviceStateOptions: null,
        unableToRepairOptions: null,
    },
    reducers: {
        updateMultiselectConfig: (state, param) => {
            state.repairActionOptions = param.payload.repair_actions;
            state.deviceStateOptions = param.payload.device_state_options;
            state.unableToRepairOptions = param.payload.unable_to_repair_options;
        },
        clearMultiselectConfig: (state) => {
            state.repairActionOptions = null;
            state.deviceStateOptions = null;
            state.unableToRepairOptions = null;
        },
    },
});

export const multiselectOptionsReducer = multiselectOptionsSlice.reducer;
export const { updateMultiselectConfig, clearMultiselectConfig } = multiselectOptionsSlice.actions;
