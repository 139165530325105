import { store } from '../app/store';
import { getApiGatwayUrl } from './deviceStatusHandler';

const apigClientFactory = require('aws-api-gateway-client').default;

export const debugAndFixDevice = (deviceId: string, forceContinuousJobDeployment: boolean) => {
    const client = apigClientFactory.newClient({
        invokeUrl: getApiGatwayUrl(),
        retries: 3,
    });

    client
        .invokeApi(
            {},
            '/debug_and_fix_device',
            'POST',
            {
                queryParams: {
                    deviceId,
                    forceStartDeployment: false,
                    forceContinuousJobDeployment,
                },
                headers: {
                    Authorization: store.getState().userData.userInfo!.jwt,
                },
            },
            null,
        )
        .then((response: any) => {
            // if success, response should just be true
            console.log(response.data);
        })
        .catch((response: any) => {
            console.error(JSON.stringify(response.data));
        });
};
