import * as React from 'react';
import { TopNavigation } from '@amzn/awsui-components-react';
import { clearOnboarding } from '../slice/onboardingPageSlice';
import { clearData } from '../slice/installationDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { EventType, EventName, logMetricEvent } from '../services/eventService';
import { clearUserInfo } from '../slice/userInfoSlice';
import { useEffect, useState } from 'react';
import { clearDynamicConfig } from '../slice/dynamicConfigSlice';

const i18nStrings = {
    searchIconAriaLabel: 'Search',
    searchDismissIconAriaLabel: 'Close search',
    overflowMenuTriggerText: 'More',
    overflowMenuTitleText: 'All',
    overflowMenuBackIconAriaLabel: 'Back',
    overflowMenuDismissIconAriaLabel: 'Close menu',
};

const emailTemplate = (emailAddress: string) =>
    `mailto:${emailAddress}?subject=AmazonLogisticDevices Feedback&body=Message`;
const supportItems = (emailAddress: string) => {
    return {
        id: 'support-group',
        text: 'Feedback',
        items: [
            {
                id: 'feedback',
                text: `Send Feedback to Amazon`,
                href: emailTemplate(emailAddress),
                external: true,
                externalIconAriaLabel: ' (opens in new tab)',
            },
        ],
    };
};

const profileActions = [
    { type: 'button', id: 'profile', text: 'Change Password', href: 'changepassword' },
    { type: 'button', id: 'signout', text: 'Sign out', href: '/' },
];

const menuItems = (emailAddress: string) => [
    {
        id: 'profile-group',
        text: 'Profile',
        items: profileActions,
    },
    supportItems(emailAddress),
];
function getName(userData: any) {
    if (userData.userInfo) {
        return userData.userInfo.given_name + ' ' + userData.userInfo.family_name;
    } else {
        return '';
    }
}
function getEmailAndCompany(userData: any) {
    if (userData.userInfo) {
        return userData.userInfo.email + ' ' + '[' + userData.userInfo['custom:company'] + ']';
    } else {
        return '';
    }
}
const appName = 'AmazonLogisticsDevices';
const NavBar = () => {
    const dispatch = useDispatch();
    const emailAddress = useSelector((state: any) => state.dynamicConfig.feedbackEmail);
    const userData = useSelector((state: any) => state.userData);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        if (userData.userInfo && emailAddress) {
            setIsLoggedIn(true);
        }
    }, [userData, emailAddress]);
    if (!isLoggedIn) {
        return (
            <TopNavigation
                i18nStrings={i18nStrings}
                identity={{
                    href: '/',
                    title: appName,
                    // logo: { src: logo, alt: 'Service name logo' },
                }}
            />
        );
    } else {
        return (
            <TopNavigation
                i18nStrings={i18nStrings}
                identity={{
                    href: '/',
                    title: appName,
                    // logo: { src: logo, alt: 'Service name logo' },
                }}
                utilities={[
                    {
                        type: 'menu-dropdown',
                        text: getName(userData),
                        description: getEmailAndCompany(userData),
                        iconName: 'user-profile',
                        items: menuItems(emailAddress),
                        onItemClick: (e) => {
                            if (e.detail.id == 'signout') {
                                logMetricEvent({
                                    eventName: EventName.MANUAL_SIGN_OUT,
                                    eventType: EventType.COUNT,
                                });
                                dispatch(clearOnboarding());
                                dispatch(clearData());
                                dispatch(clearUserInfo());
                                dispatch(clearDynamicConfig());
                            }
                        },
                    },
                ]}
            />
        );
    }
};

export default NavBar;
