import * as React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import { Box, SpaceBetween, Button } from '@amzn/awsui-components-react';
import AutoCodeCameraReader from './autocamera';
import Camera from './camera';
import { useSearchParams } from 'react-router-dom';
import AutocameraV2 from './camera/autocameraV2';

type Props = {
    header: string;
    setUserImageCallback: (img: any, imgCanvas?: any) => void;
    isValidTextOutput: (text: string) => boolean;
    setTextOutput: (text: string) => void;
    visible: boolean;
    setVisible: (visible: boolean) => void;
};

const CameraModal = ({
    header,
    setUserImageCallback,
    visible,
    setVisible,
    setTextOutput,
    isValidTextOutput,
}: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const overrideCamera = searchParams.get('use_auto_camera');
    const useAutoCamera = overrideCamera === 'true';

    const callback = (img: any) => {
        setUserImageCallback(img);
        setVisible(false);
    };

    const textCallback = (text: string): void => {
        setTextOutput(text);
        setVisible(false);
    };

    return (
        <>
            <div id={'modalroot'} />
            <Modal
                modalRoot={document.getElementById('modalroot') as HTMLElement}
                onDismiss={() => setVisible(false)}
                visible={visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setVisible(false)}>
                                Cancel
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header={header}
            >
                {useAutoCamera ? (
                    <AutocameraV2 isValidTextOutput={isValidTextOutput} setTextOutput={textCallback} />
                ) : (
                    <Camera setUserImageCallback={callback} />
                )}
            </Modal>
        </>
    );
};
export default CameraModal;
